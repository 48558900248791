.first {
    grid-area: first;
}

.second {
    grid-area: second;

    @include media-breakpoint-up(xl) {
        min-height: 65px;
    }
}

.third {
    grid-area: third;

    @include media-breakpoint-down(lg) {
        margin-top: 20px;
    }
}

.grid-container {
    display: grid;
    grid-template-areas:
        'first first second second second second second second second second second second'
        'first first third third third third third third third third third third'
        'first first third third third third third third third third third third';
    gap: 20px;

    @include media-breakpoint-up(xl) {
        grid-template-columns: 20%;
    }

    // @include media-breakpoint-down(lg) {
    //     grid-template-areas:
    //         'first first first first second second second second second second second second'
    //         'first first first first second second second second second second second second'
    //         'first first first first third third third third third third third third'
    //         'first first first first third third third third third third third third';
    // }

    @include media-breakpoint-down(lg) {
        grid-template-areas:
            'second second second second second second second second second second second second'
            'first first first first first first first first first first first first'
            'third third third third third third third third third third third third'
            'third third third third third third third third third third third third';
        gap: 10px;
    }
}
// .fade-fast-enter,
.fade-fast-enter-active {
    display: block;
    animation: fadeIn 0.33s linear 0.33s 1 both;
}

.fade-fast-leave {
    display: none;
}

.fade-fast-leave-active {
    display: none;
}

//
// Animate css
//

[v-cloak][opacity] {
    opacity: 0 !important;
    pointer-events: none !important;
}

:root {
    --animate-duration: 1.2s;
    --animate-delay: .2;
    --animate-repeat: 1;
    --animate-timing: ease;
    --animate-fill-mode: forwards;
}

.animate__hidden {
    visibility: hidden;
    opacity: 0;
}

.animate__animated {
    animation-duration: var(--animate-duration);
    animation-delay: var(--animate-delay);
    animation-fill-mode: var(--animate-fill-mode);
    animation-timing-function: var(--animate-timing);
    visibility: visible !important;
    transition: 2s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.animate__fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    animation-timing-function: var(--animate-timing);
    opacity: 0;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 40px, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.animate__fadeInUp {
    animation-name: fadeInUp;
    transform: translate3d(0, 40px, 0);
    opacity: 0;
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translate3d(40px, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.animate__fadeInRight {
    animation-name: fadeInRight;
    opacity: 0;
    transform: translate3d(40px, 0, 0);
}

@keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        opacity: 0;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.animate__slideInLeft {
    -webkit-animation-name: slideInLeft;
    animation-name: slideInLeft;
    transform: translate3d(-100%, 0, 0);
}

@keyframes slideInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        opacity: 0;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.animate__slideInRight {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
    transform: translate3d(100%, 0, 0);
}

@keyframes slideInDown {
    from {
        transform: translate3d(0, -100%, 0);
        opacity: 0;
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.animate__slideInDown {
    transform: translate3d(0, -100%, 0);
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
}

@keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.animate__slideInUp {
    transform: translate3d(0, -100%, 0);
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp;
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    100% {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}

.animate__zoomIn {
    animation-name: zoomIn;
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
}

@keyframes backInUp {
    0% {
        transform: translateY(1200px) scale(0.7);
        opacity: 0.7;
    }

    80% {
        transform: translateY(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        transform: translateY(0px) scale(1);
        opacity: 1;
    }
}

.animate__backInUp {
    transform: translateY(1200px) scale(0.7);
    opacity: 0.7;
    -webkit-animation-name: backInUp;
    animation-name: backInUp;
}

@keyframes first-words {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    40% {
        opacity: 1;
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes rest-words {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0%);
    }
}

//
// Reveal text
//

.reveal-text {
    .first-word {
        display: inline-block;

        >span {
            overflow: hidden;
            display: inline-block;
            white-space: nowrap;
            visibility: hidden;
            opacity: 0;
            transform: translateX(100%);
        }
    }

    .rest-words {
        display: inline-block;
        overflow: hidden;

        >span {
            visibility: hidden;
            display: inline-block;
            transform: translateX(-100%);
        }
    }

    &.animate__revealing {
        .first-word {
            >span {
                visibility: visible;
                animation-name: first-words;
                animation-duration: 2s;

                animation-delay: var(--animate-delay);
                animation-timing-function: var(--animate-timing);
                animation-fill-mode: var(--animate-fill-mode);
            }
        }

        .rest-words {
            >span {
                visibility: visible;
                animation-name: rest-words;
                animation-duration: 2s;
                animation-delay: 2s;
                animation-timing-function: var(--animate-timing);
                animation-fill-mode: var(--animate-fill-mode);
            }
        }
    }
}

@keyframes animate-title {
    0% {
        transform: translateX(50px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes animate-heading-line {
    0% {
        opacity: 0;
        transform: translateX(-30px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes animate-subtitle {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

.reveal-heading {
    .title {
        white-space: nowrap;
        transform: translateX(50px);
    }

    .subtitle {
        &::before {
            opacity: 0;
            transform: translateX(-30px);
        }

        &-inner {
            overflow: hidden;

            >span {
                display: inline-block;
                // white-space: nowrap;
                transform: translateX(-100%);
            }
        }
    }

    &.animate__heading_revealing {
        .title {
            animation: animate-title 0.4s ease 0s forwards;
        }

        .subtitle {
            &::before {
                animation: animate-heading-line 0.35s ease 0.4s forwards;
            }

            &-inner {
                >span {
                    animation: animate-subtitle 1.2s ease 0.9s forwards;
                }
            }
        }
    }
}

.animation-view {
    position: absolute;
    top: 10%;
    left: 0;
    z-index: 10000;
    height: 1px;
    width: 100%;
    pointer-events: none;
    opacity: 0;

    @include media-breakpoint-up(md) {
        top: 50%;
    }
}

.page-homepage {

    .logo,
    .navbar-toggler {
        animation-delay: 4s;
    }
}

footer {
    letter-spacing: 0;
    color: white !important;
    font-size: 16px;

    hr {
        opacity: 1 !important;
    }

    a {
        color: white;
        text-decoration: none;

        &:hover {
            color: $yellow;
        }
    }

    address {
        margin-bottom: 0;


        p {
            font-size: 14px;
            line-height: 27px;
            margin-bottom: 0;

            a {
                font-size: 15px;
            }

            a,
            strong {
                color: white;
            }

            strong {
                font-size: 14px;
                margin-bottom: 5px;
            }

            @media (min-width: 375px) and (max-width: 415px) {
                font-size: 14px;
            }
        }
    }

    .footer-left {
        .form-control {
            @include media-breakpoint-up(sm) {
                max-width: 255px;
            }

            border-color: transparent;
            max-width: 240px;
            min-width: 180px;
            width: 100%;

            &::placeholder {
                font-size: 12px;
                color: black;
            }
        }

        .footer-btn {
            width: 124.92px;
            padding: 10px 15px;
        }


    }

    .footer-right {
        @include media-breakpoint-down(lg) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .address-wrapper {
            &:not(:last-of-type) {
                margin-bottom: 20px;
            }

            @include media-breakpoint-down(lg) {
                &:last-of-type {
                    padding-left: 8px;
                }
            }
        }
    }

    .footer-social-media {
        @include media-breakpoint-down(xss) {
            flex-wrap: wrap;
        }

        a {
            &:not(:last-of-type) {
                margin-right: 20px;
            }
        }

        i {
            font-size: 1.5rem;
        }
    }

    .footer-bottom {
        &-nav a {
            font-size: 16px;

            @include media-breakpoint-down(xs) {
                font-size: 14px;
            }
        }

        &-author {
            font-size: 14px;

            @include media-breakpoint-down(xs) {
                font-size: 12px;
            }
        }
    }

    .footer-nav-list {
        margin-top: 20px;

        @include media-breakpoint-up(sm) {
            margin-top: 40px;
        }

        ul {
            font-size: 18px;
            padding: 0;
            list-style: none;

            li {
                &:not(:last-child) {
                    margin-bottom: 22px;

                    @include media-breakpoint-down(sm) {
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }

    .footer-addresses {
        >div:not(:last-child) {
            margin-bottom: 34px;
        }
    }
}

/**
 *  Modals styles
 */
// enquire form modal
.enquire-form-modal {
    @include media-breakpoint-up(lg) {
        overflow-y: hidden;
    }

    .enquire-form-img {
        @include media-breakpoint-up(xs) {
            max-width: 136px;
            width: 100%;
        }
    }

    .content-padding {
        &-1 {
            @include media-breakpoint-up(md) {
                padding-right: 30px;
            }
        }

        &-2 {
            padding-right: 0px;

            @include media-breakpoint-up(xs) {
                padding-right: 30px;
            }
        }
    }

    .form-col {
        width: 100%;

        form {
            width: 100%;
        }
    }

    .modal-content {
        @include media-breakpoint-up(lg) {
            height: 100%;
        }
    }

    .modal-dialog {
        min-height: 100%;
        height: 100% !important;
        max-width: 570px;
        margin-left: auto;
        margin-right: 0;
        top: 0;

        @include media-breakpoint-up(sm) {
            right: -1px;
        }
    }

    .modal-body {
        .row {
            margin: 0 -16px;
        }
    }

    .enquire-icon {
        width: 18px;

        @include media-breakpoint-down(xss) {
            width: 16px;
        }
    }

    .enquire-form {
        max-width: 439px;

        @include media-breakpoint-down(xs) {
            max-width: 300px;
        }

        .enquire-data {
            margin: 0 -10px;

            input,
            textarea {
                background-color: #FCFCFC !important;
            }
        }
    }
}

.modal-header {
    .close {
        margin-top: -0.8rem;
        text-shadow: none;
        transition: 0.3s ease;

        &:hover,
        &:focus,
        &:focus:active,
        &:active {
            color: $white;
        }
    }
}

.filter-modal {
    z-index: 1500;
    padding-left: 0;
    padding-right: 0;

    .modal-dialog {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        height: 100%;

        @include media-breakpoint-down(sm) {
            width: 75%;
            font-size: 13px;
        }

        .modal-header {
            h5 {
                font-size: 24px;
            }
        }

        .modal-footer {
            button {
                @include media-breakpoint-down(xss) {
                    font-size: 0.7rem;
                }
            }
        }
    }

    .modal-body {
        overflow-x: hidden;

        .product-list__filters {

            .dropdown a,
            .form-check-label,
            .form-control {
                @include media-breakpoint-down(xs) {
                    font-size: 14px !important;
                }
            }

            .dropdown a {
                @include media-breakpoint-down(xs) {
                    padding: 10px;
                }
            }
        }
    }
}

.categories-modal {
    @include media-breakpoint-down(lg) {
        display: none;
    }

    .modal-dialog {
        max-width: 100%;
        top: 103px;

        .navbar-menu .nav-link {
            font-size: 18px;
            width: fit-content;

            &:hover {
                color: $secondary;
            }
        }

        .modal-content {
            padding: 20px 0;
        }

        .modal-body {
            .row {
                >div {
                    margin-bottom: 10px;
                }

                .item {
                    display: block;
                    border-bottom: 1px solid $light-blue;
                    padding: 10px;
                    min-height: 70px;
                    max-height: 70px;
                    display: flex;
                    align-items: center;

                    img {
                        filter: brightness(0);
                        margin-right: 20px;
                        max-width: 50px;
                        max-height: 50px;
                    }

                    &:hover {
                        background-color: $light-blue;
                    }
                }
            }
        }

        .modal-footer {
            overflow: hidden;
            border: 0;
            padding-top: 0;

            .title {
                >div {
                    position: relative;
                    padding: 0 20px;

                    &::before,
                    &::after {
                        background: $gray-300;
                        content: "";
                        height: 1px;
                        position: absolute;
                        top: 50%;
                        width: 581px;
                    }

                    &::before {
                        right: 100%;
                    }

                    &::after {
                        left: 100%;
                    }
                }
            }

            .top-four-items {
                >div {

                    &:first-child,
                    &:nth-child(2) {
                        @include media-breakpoint-down(lg) {
                            margin-bottom: 20px;
                        }
                    }
                }

                .img-wrapper {
                    margin-right: 20px;
                    border-radius: 50%;
                    background-color: $gray-200;
                    min-width: 90px;
                    height: 90px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
                    border: 10px solid $gray-200;

                    img {
                        width: 70px;
                    }
                }

                .right-content {
                    width: calc(100% - 75px);

                    .description {
                        font-size: 14px;
                        text-transform: capitalize;
                        margin-bottom: 5px;
                        text-overflow: ellipsis;
                    }

                    .price {
                        color: $secondary;
                        font-style: italic;
                        font-weight: bold;
                        font-size: 16px;

                        span {
                            color: $primary;
                            text-decoration: line-through;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}

.menu-right {
    @include media-breakpoint-up(lg) {
        display: none !important;
    }

    .accordion {
        .card {
            button {
                font-size: 1.5rem;
                color: $gray-500;
                justify-content: unset;
                padding: 0;

                &:active,
                &:focus {
                    color: $primary;
                }
            }

            .card-body {
                padding-top: 10px;
                margin-bottom: 10px;

                ul {
                    li {
                        &:not(:last-child) {
                            margin-bottom: 20px;
                        }

                        .item {
                            font-size: 18px;

                            img {
                                filter: brightness(0);
                                margin-right: 15px;
                                max-width: 50px;
                                max-height: 50px;
                            }
                        }
                    }
                }
            }
        }
    }

    .close {
        margin: 0;
        padding: 0;
        opacity: 1 !important;
        text-shadow: none;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        font-size: 2.45rem;
        color: $secondary;
    }

    .modal-header {
        min-height: 50px;
        border: 0;
    }

    .modal-dialog {
        position: fixed;
        margin: auto;
        width: 100%;
        max-width: 360px;
        height: 100%;
        transform: translate3d(0%, 0, 0) !important;
        right: -360px;
        background-color: $white;
        transition: right 0.3s ease-out !important;

        ul {
            hr {
                border-top: 2px solid $secondary;
            }
        }
    }

    &.show .modal-dialog {
        right: 0;
    }

    .modal-content {
        border: 0;
        border-radius: 0;
        height: 100%;
        overflow-y: auto;
    }

    .navbar-menu {
        text-align: center;
    }

    .nav-link {
        font-size: 1.5rem;
    }
}

.sidebar-cart {
    z-index: 9999999;

    .offcanvas-header {
        .title {
            margin: 0;
            color: white;
            text-transform: uppercase;
        }
    }

    .offcanvas-body {
        overflow-y: scroll;
    }

    .offcanvas-footer {
        display: block;
        padding: 1rem 2rem;
    }
}

.login-modal {
    .modal-dialog {
        max-width: 500px;
        width: 100%;
    }

    .nav-link,
    .btn-secondary {
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
    }

    .modal-header {
        border: 0;

        .nav-account-tabs {
            border: 0;

            .nav-item {
                margin-bottom: 0;
            }

            .nav-link {
                border: 0;
                margin-bottom: 0;

                &:first-child {
                    padding: 0.651rem 1rem;
                }
            }
        }

        .close {
            font-size: 30px;
        }
    }

    .modal-footer {
        border: 0;
    }
}

header .offcanvas-backdrop.show {
    opacity: 0 !important;
}

header div.header-menu-offcanvas {

    @include media-breakpoint-down(xs) {
        max-width: 100% !important;
    }

    .mobile-menu-items {
        ul li {
            padding: 16px 29px;

            a {
                text-decoration: none;
                font-size: 18px;
                color: black;
                font-weight: 300;
            }
        }
    }

    .offcanvas-logins {
        display: flex;
        justify-content: space-between;
        width: 90%;
        padding-top: 20px;
        padding-bottom: 20px;

        a {
            color: black;
            text-decoration: none;

            &:first-child {
                order: 1;
            }
        }

        .login-icon {
            display: flex;
            align-items: center;

            svg {
                margin-right: 5px;
            }
        }
    }

    .offcanvas-body {
        position: relative;
    }

    .offcanvas-header {
        border-bottom: 1px solid $primary;
    }

    &.offcanvas-start {
        top: 92px;
    }

    max-width: 400px;
    background-color: white;

    .accordion-item {
        border: none;
        background-color: inherit;
        border-bottom: 1px solid hsla(0, 0%, 95%, 1);
        border-radius: 0 !important;
    }

    .accordion-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: inherit;

        button.accordion-button {
            background-color: inherit;
            border-radius: 0 !important;

            &::after {
                background-image: url("data:image/svg+xml,%3Csvg width='17' height='9' viewBox='0 0 17 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L8.5 8L16 1' stroke='%23828282' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            }

            &.main-category-button {
                background-color: $light-blue;
                color: $primary !important;

                &::after {
                    background-image: url("data:image/svg+xml,%3Csvg width='17' height='9' viewBox='0 0 17 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L8.5 8L16 1' stroke='%230077cf' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                }

                &.collapsed {
                    background-color: white !important;
                    columns: black;

                    &::after {
                        background-image: url("data:image/svg+xml,%3Csvg width='17' height='9' viewBox='0 0 17 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L8.5 8L16 1' stroke='%23828282' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                    }
                }
            }
        }

        a {
            color: black;
            padding: 8px;
            font-size: 18px;
            text-decoration: none;
            font-weight: 300;
        }
    }

    .accordion-body {
        &.first-accordion-body {
            padding: 1.2rem 0 1.2rem 1.25rem;

            ul li {
                &:not(:last-of-type) {
                    margin-bottom: 25px;
                }
            }
        }

        .accordion-item {
            border: none;
        }
    }

    .sub-category-accordion {
        .accordion-header {
            a {
                padding-left: 0;
                padding-top: 0;
                padding-bottom: 0;
            }

            button {
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        .accordion-body {
            padding-right: 9px;
            padding-bottom: 0;
        }

        ul.sub-category-element-list {
            padding: 10px;
            margin-top: 10px;

            >li {
                &:not(:last-child) {
                    margin-bottom: 30px;
                }

                font-size: 18px;
                font-weight: 300;

                a {
                    text-decoration: none;
                    color: black;
                }
            }
        }
    }

    .sub-sub-category-accordion {
        .accordion-header {
            font-weight: normal;

            * {
                padding: 0;
            }
        }

        .accordion-body {
            padding-bottom: 0;
            padding-top: 0;
            padding-left: 0;
        }

        .sub-sub-list {
            padding-left: 0;
            padding-bottom: 0;
            padding-top: 20px;

            a {
                color: black;
            }

            li {
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }
        }
    }
}

.confirm-modal.modal-dialog {
    .modal-content {
        border-color: #dadce0;
    }

    button.close {
        &:active {
            outline: none;
        }

        font-size: 50px;
    }

    .modal-body {
        overflow-x: hidden;
        padding: 35px;

        .text-wrapper {
            text-align: center;
            padding-bottom: 24px;

            .title {
                font-size: 22px;
            }
        }

        p {
            font-size: 14px;
        }

        .btn {
            font-size: 16px;
            color: black !important;

            @include media-breakpoint-up(lg) {
                width: 250px;
            }
        }
    }

    .buttons-wrapper {
        display: flex;
        justify-content: space-between;

        @media (max-width: 576px) {
            flex-direction: column-reverse;
        }

        .btn {
            flex-grow: 1;

            @media (min-width: 576px) {
                &:first-child {
                    margin-right: 20px;
                }
            }

            @media (max-width: 576px) {
                &:nth-child(2) {
                    margin-bottom: 16px;
                }
            }
        }
    }

    &.modal-lg {
        .text-wrapper {
            text-align: start;
            padding-bottom: 10px;
        }

        .absolute-border-bottom {
            display: none;
        }

        .modal-icon {
            display: none;
        }

        .modal-header {
            position: absolute;
            right: 0;
            z-index: 10;
        }

        .buttons-wrapper {
            @media (min-width: 576px) {
                float: right;
            }

            .btn {
                min-width: 140px;

                @media (max-width: 576px) {
                    width: 100%;
                }

                &:first-child {
                    @media (min-width: 576px) {
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}

// offcanvas engquire
.offcanvas-enquire {
    overflow-x: hidden;

    .offcanvas-header {
        h5 {
            margin: 0;
        }

        // align-items: baseline;
        border-bottom: 1px solid $gray-ef;
    }

    .offcanvas-body {
        overflow-x: hidden;
        padding: 20px 26px;

        >div {
            position: relative;

            &:not(:first-child) {
                padding-top: 20px;
                padding-bottom: 20px;
            }

            &:not(:last-child) {
                &::before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    width: 500px;
                    left: -20px;
                    height: 11;
                    border-bottom: 1px solid $gray-ef;
                }
            }


        }

        .form-check {
            &:first-child {
                margin-right: 30px;
            }
        }

        .reference {
            color: $primary;
            font-size: 18px;
            font-weight: bold;

            span {
                color: black;
                font-size: 14px;
                font-weight: normal;
            }
        }

        &-no-horizontal-lines {
            >div {
                &:not(:last-child) {
                    &::before {
                        display: none;
                    }
                }

            }
        }
    }

    .enquire-data {
        >div {
            &:not(:last-child) {
                margin-bottom: 18px;
            }
        }
    }
}

// offcanvas general 
.offcanvas {
    .offcanvas-header {
        position: relative;

        .btn-close {
            position: absolute;
            top: 12px;
            right: 12px;
        }
    }
}

.warning-modal {
    .modal-dialog {
        max-width: 872px;
    }

    .btn-warning {
        max-width: 380px;
        width: 100%;
        height: 48px;
    }
}

.reset-password-modal {
    .modal-dialog {
        max-width: 672px;
    }

    .btn-cancel {
        height: 48px;
        background-color: $gray-ef;
    }

    .btn-warning {
        height: 48px;
    }
}

// offcanvas-add-to-bag
.offcanvas-add-to-bag {

    .offcanvas-header,
    .offcanvas-body,
    .offcanvas-footer {
        @include media-breakpoint-up(md) {
            padding-left: 37px;
            padding-right: 37px;
        }
    }

    .offcanvas-header {
        background-color: #F3FCF7;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .offcanvas-body {
        .enquire-form-img {
            max-width: 68px;
        }

        .brook-overlay-logo {
            max-width: 19.5px;
        }

        .price {
            font-size: 17px;

            @include media-breakpoint-down(sm) {
                font-size: 16px;
            }

            a svg {

                &:active,
                &:focus {
                    transform: scale(0.8);
                }
            }
        }

        .title {
            max-width: 218px;
        }

        .bag-item {
            padding-bottom: 21px;

            &:not(:last-child) {
                margin-bottom: 22px;
                border-bottom: 1px solid $gray-ef;
            }

            .item-quantity {
                max-width: 40%;
            }
        }
    }

    .offcanvas-footer {
        padding: 1.25rem;
        padding-bottom: 0;

        @include media-breakpoint-up(md) {
            padding-left: 37px;
            padding-right: 37px;
        }

        .order-summary {
            display: flex;
            justify-content: space-between;
            border-top: 1px solid $gray-ef;
            border-bottom: 1px solid $gray-ef;
        }

        button {
            @include media-breakpoint-up(sm) {
                width: 188px;
            }

            height: 45px;
        }
    }
}

.modal-proceed {
    .modal-content {
        border-radius: 10px;
    }

    .modal-header {
        border: 0;
        padding: 0;
        z-index: 1;

        .btn-close {
            position: absolute;
            top: 26px;
            right: 20px;
            margin: 0;
            padding: 5px;
            width: 20px;
            height: 20px;
            background-size: 20px;
            opacity: 1;
        }
    }

    hr {
        height: 100%;
        background: #efefef;
        margin: 0;
        width: 1px;
    }

    .modal-body {
        .btn {
            max-width: 272px;
            width: 100%;
        }
    }

    .custom_order {
        height: 100%;
    }
}
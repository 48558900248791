/**
 * Sections styles
 */

.category-main-content {
    background-color: #fcfcfc;

    .input-group {
        select {
            border-radius: 5px !important;
        }
    }
}

.contact-icons {
    >div>div {
        min-width: 270px;
    }
}

.category-top-images-wrapper {
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;

    .category-top-images {
        @include media-breakpoint-up(md) {
            margin-left: 58px;
        }

        div {
            @include media-breakpoint-up(lg) {
                margin-right: 37px;
            }
        }

        img {
            @include media-breakpoint-up(md) {
                max-width: 138px;
            }
        }
    }
}

.mobile-filter-modal {
    width: fit-content;
}

.top-content-bar {
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;

    @include media-breakpoint-up(md) {
        max-height: 64px;
        height: 100%;
    }

    @include media-breakpoint-down(xl) {
        border: 1px solid #efefef;
    }
}


.section-form-search-doc {
    overflow: hidden;

    .bg-right {
        @include media-breakpoint-up(lg) {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100vw;
        }
    }
}

/**
 *  Homepage
 */

.homepage-banner {
    position: relative;

    @include media-breakpoint-down(md) {
        background-color: $light-blue;
    }
}

.listing-wrapper {
    &-1 {
        @include media-breakpoint-up(lg) {
            margin-top: -100px;
        }
    }

    &-2 {
        @include media-breakpoint-up(lg) {
            margin-top: -85px;
        }
    }
}

.products-listing {
    // padding: 0 15px;

    &-1 {
        &>div {
            background-color: white;
            margin-bottom: 10px;

            @include media-breakpoint-down(xss) {
                padding: 0 5px;
            }
        }
    }

    &-2 {
        // padding: 0 5px;
        padding: 0;

        &>div {
            padding: 0 5px;
            margin-bottom: 0px;

            @include media-breakpoint-up(xl) {
                margin-bottom: 10px;
            }

            @include media-breakpoint-down(xl) {
                padding: 10px;
            }

            // &:first-child {
            //     @include media-breakpoint-down(xss) {
            //         padding: 0 10px 10px 10px;
            //     }
            // }

            @include media-breakpoint-down(xss) {
                padding: 10px 5px;
            }
        }

        .card-product {
            background-color: white;
        }
    }
}

.filters-wrapper {
    &-1 {
        width: calc(100% + 4px);
        top: -2px;
        left: -2px;
        position: relative;
        z-index: 0;
        padding: 0 15px;
    }
}

.filters {

    padding: 20px 0;

    &-1 {
        margin-left: -15px;
        margin-right: -15px;
        justify-content: space-around;

        @include media-breakpoint-up(lg) {
            height: 269px;
        }

        .category {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-height: 115px;
            color: white;

            filter: brightness(39%);

            @include media-breakpoint-up(lg) {
                margin-top: 15px;
            }

            @include media-breakpoint-down(lg) {
                margin-bottom: 17px;
            }

            @include media-breakpoint-down(xss) {
                padding-right: 10px;
                padding-left: 10px;
            }

            &:hover,
            &:focus,
            &:focus:active,
            &:active {
                filter: brightness(100%);
            }

            .image-wrapper {
                height: 70px;
                display: flex;
                align-items: center;
                justify-content: center;

                .image {
                    @include media-breakpoint-down(xxss) {
                        max-width: 85%;
                    }
                }

                margin-bottom: 15px;
            }

            .title {
                text-transform: uppercase;
                font-weight: bold;
                font-size: 18px;

                @include media-breakpoint-down(xxss) {
                    font-size: 16px;
                }
            }

            &:before {
                content: "";
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -15px;
                width: 0%;
                background-color: $secondary;
                height: 7px;
                transition: 0.1s ease-out;
            }

            &:hover,
            &:focus,
            &:focus:active,
            &:active {
                cursor: pointer;

                &:before {
                    width: 30px;
                }
            }
        }
    }

    &-2 {
        @include media-breakpoint-down(sm) {
            align-items: center;
            justify-content: flex-start;
        }

        .btn {
            font-size: 20px;
            height: 60px;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

        li {
            @include media-breakpoint-down(md) {
                width: 100%;
                padding: 0 10px;

                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
        }

        &>div {
            &:nth-child(2) {
                .btn {
                    font-size: 14px;
                    max-height: 66px;
                    height: 100%;
                    max-width: 160px;
                    width: 100%;

                    @include media-breakpoint-down(sm) {
                        max-width: 100%;
                        margin-bottom: 10px;
                        font-size: 16px;
                    }
                }
            }
        }

        @include media-breakpoint-up(lg) {
            height: 185px;
            padding-top: 22px;
        }
    }
}

.color-tiles {
    line-height: 1;

    .black-plus {
        position: absolute;
        z-index: 10;
        top: 44%;
        right: -22px;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-down(xs) {
            top: unset;
            right: 0;
            left: 0;
            bottom: -22px;
        }
    }

    .title {
        font-size: 30px;
    }

    .subtitle {
        font-size: 30px;
        font-weight: 900;
    }

    .description {
        font-size: 20px;
    }

    .tile {
        .image {
            z-index: 3;
        }

        &-1 {
            background-color: #e4e822;

            @include media-breakpoint-up(xl) {
                // height: 400px;
            }

            .description {
                color: #989b18;
            }

            .btn {
                @include media-breakpoint-up(xs) {
                    max-width: 145px;
                }
            }
        }

        &-2,
        &-3 {
            @include media-breakpoint-up(lg) {
                height: 50%;
            }

            @include media-breakpoint-up(xs) {
                min-height: 221px;
            }
        }

        &-2 {
            background-color: #53c8e6;

            .discount {
                color: white;
                font-family: "Mulish", sans-serif;
                font-size: 75px;
                font-weight: 800;
            }
        }

        &-3 {
            background-color: #2e3030;
            color: white;
        }

        &-4 {
            background-color: #ffb25e;
            justify-content: center;

            img {
                padding: 40px 15px;

                @include media-breakpoint-up(lg) {
                    margin: 0;
                    position: relative;
                    top: 32px;
                    padding: 0;
                }
            }
        }

        &-5,
        &-6 {
            @include media-breakpoint-down(xs) {
                min-height: 303px;
            }
        }

        &-5 {
            background-color: $primary;
            color: white;

            .description {
                color: #808080;
                line-height: 1.4;
            }
        }

        &-6 {
            background-color: white;
            border: 2px solid black;

            .title {
                font-size: 24px;
                font-weight: 900;
                text-transform: uppercase;

                @include media-breakpoint-down(xss) {
                    font-size: 20px;
                }
            }

            .price {
                font-size: 24px;
            }

            &-v2 {
                .title {
                    font-size: 20px;
                }

                .price {
                    strong {
                        color: $secondary;
                        font-weight: 900;
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    &-2 {
        div {
            .subtitle {
                font-size: 28px;
                font-weight: 700;
            }
        }
    }
}

.bottom-icons {
    .wrapper {
        font-size: 20px;
        font-weight: 900;
        font-family: "Mulish", sans-serif;

        .text {
            width: 280px;
        }

        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    &-2 {
        img {}
    }
}

/**
 *  Product list
 */
.product-list {
    &__filters {
        height: 100%;
        padding-bottom: 30px;

        >div {
            border-bottom: 1px solid #b3b9c5;
            padding: 20px 0;

            &:last-child {
                border: 0;
            }
        }

        .filters__title {
            font-weight: bold;
            font-size: 18px;

            &-small {
                margin-bottom: 15px;
            }

            &-big {
                font-size: 22px;
            }
        }

        .checkboxes {
            &-normal {
                .form-check {
                    align-items: flex-start;
                    margin-bottom: 15px;

                    &:not(:last-child) {
                        margin-right: 15px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    label {
                        font-size: 16px;
                        padding-left: 6px;
                    }
                }
            }

            &-colors {
                .form-check {
                    margin: 5px;

                    @include media-breakpoint-up(xl) {
                        margin: 8px;
                    }

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }

        .input-group {
            .input-group-prepend {
                margin-right: 10px;
            }

            .form-control {
                max-width: 100px;
                text-align: center;

                &::placeholder {
                    color: #a0a0a0;
                }
            }
        }

        .dropdown {
            background-color: $white;
            box-shadow: inset 0 0 0 1px $gray-400;
            width: 100%;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }

            .dropdown-toggle {
                text-transform: none;
                color: $gray-400;
            }
        }

        &-modal {
            >div {
                padding: 20px 20px;
            }
        }
    }

    &__content {
        .content__top-bar {
            .top-bar__title {
                font-weight: bold;
                font-size: 22px;
            }

            input,
            select {
                background-color: $gray-100;
                height: 45px;
                border: 0;
            }

            select {

                &:focus,
                &:active {
                    box-shadow: none;
                }
            }

            .input-group {
                margin: 0;
                position: relative;

                &::after {
                    top: 14px;
                    right: 14px;
                    position: absolute;
                    content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktc2VhcmNoIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGQ9Ik0xMS43NDIgMTAuMzQ0YTYuNSA2LjUgMCAxIDAtMS4zOTcgMS4zOThoLS4wMDFjLjAzLjA0LjA2Mi4wNzguMDk4LjExNWwzLjg1IDMuODVhMSAxIDAgMCAwIDEuNDE1LTEuNDE0bC0zLjg1LTMuODVhMS4wMDcgMS4wMDcgMCAwIDAtLjExNS0uMXpNMTIgNi41YTUuNSA1LjUgMCAxIDEtMTEgMCA1LjUgNS41IDAgMCAxIDExIDB6Ii8+Cjwvc3ZnPg==");
                    z-index: 5;
                    cursor: pointer;
                }

                input {
                    &::placeholder {
                        color: $gray-300;
                        font-size: 16px;
                        font-weight: normal;
                    }
                }
            }

            .top-bar__navigation {
                font-size: 16px;
                background-color: $gray-100;
                height: 45px;

                span {
                    font-weight: bold;
                }

                i {
                    cursor: pointer;

                    &:active,
                    &:focus {
                        transform: scale(0.9);
                    }
                }
            }
        }
    }

    .input-checkbox.input-field-category {
        font-size: 12px;

        .name {
            font-size: 12px;
            max-width: calc(100% - 45px);
        }
    }

    .input-checkbox {
        align-items: center;
        color: $gray-500;
        cursor: pointer;
        display: inline-flex;
        font-size: 12px;
        font-weight: 300;

        input {
            display: none;

            &:checked~.tickbox {
                border-color: $primary;

                &:before {
                    @extend .fontawesome;
                    color: $primary;
                    @include center();
                    content: "\f078";
                }
            }
        }

        &.input-checkobox-circle {
            align-items: flex-start;
            color: $black;
            font-size: .875rem;

            .name {
                font-weight: 700;
                margin-left: 0;
                padding: 4px 0 5px;

            }

            input {
                &:checked~.tickbox-circle {
                    &::before {
                        opacity: 1;
                    }
                }
            }
        }

        .tickbox-circle {
            background-color: $white;
            border: 1px solid $border-color;
            border-radius: 100%;
            flex-shrink: 1;
            height: 30px;
            margin-right: 15px;
            min-width: 30px;
            position: relative;
            width: 30px;

            &:before {
                background-color: $primary;
                border-radius: 100%;
                content: "";
                display: block;
                height: 14px;
                @include center();
                opacity: 0;
                transition: .2s ease;
                width: 14px;
            }

            .small-text {
                font-size: .8125rem;
            }
        }



        .price {
            flex-grow: 1;
            min-width: 69px;
            text-align: right;
        }

        .body {
            padding-right: 5%;
        }

        .delivery-img {
            margin-top: 8px;
            max-height: 45px;
            max-width: 75px;
        }

        .tickbox {
            border: 1px solid $border-color;
            border-radius: 2px;
            display: block;
            flex-shrink: 1;
            font-size: 12px;
            height: 16px;
            min-width: 16px;
            position: relative;
            transition: 0.3s ease;
            width: 16px;
            z-index: 10;
        }

        .name {
            color: $black;
            display: block;
            margin-left: 10px;
            margin-right: 3px;
            position: relative;
            text-transform: uppercase;
            transition: 0.3s ease;
            z-index: 10;
        }

        .count {
            position: relative;
            transition: 0.2s ease;
            z-index: 10;

            &::before {
                content: quote($string: "(");
            }

            &::after {
                content: quote($string: ")");
            }
        }

        // Category input

        &.input-field-category {
            background-color: $gray-100;
            display: flex;
            font-size: 13px;
            overflow: hidden;
            padding: 1rem;
            position: relative;
            margin-bottom: 10px;


            input:checked {
                ~.bg {
                    background: $secondary;
                    bottom: 0;
                    color: $white;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    transition: 0.15s ease;
                    z-index: 5;
                }

                ~.name,
                ~.count {
                    color: $white;
                }

                ~.tickbox {
                    &::before {
                        @extend .fontawesome;
                        content: "\f068";
                    }
                }
            }

            .tickbox {
                background: $white;
                border: 0;
                @include center(false, true);
                border-radius: 100%;
                color: $primary;
                display: block;
                font-size: 8px;
                font-weight: 700;
                height: 20px;
                position: absolute;
                right: 15px;
                text-align: center;
                width: 20px;
                z-index: 10;

                &::before {
                    @extend .fontawesome;
                    color: $gray-900 !important;
                    @include center();
                    content: "\f067";
                }
            }

            .name {
                color: $gray-900;
                font-size: 13px;
                font-weight: 400;
                margin: 0 3px 0 0;
                @include truncate-single(calc(100% - 65px));
                transition: 0.15s ease;
            }
        }

        &.checkbox-square {

            color: $black;

            input:checked {
                ~.tickbox {
                    border-color: $black;

                    &::before {
                        color: $black;
                    }
                }
            }

            .tickbox {
                border: 1px solid $black;
                border-radius: 0;
                display: block;
                margin-right: 10px;
            }

            .text {
                padding-top: 1px;
            }

            a {
                color: $primary;
                text-decoration: underline;
            }
        }
    }
}

.product-review {
    display: flex;
    align-items: flex-start;
    width: 100%;

    .image {
        position: relative;
        display: inline-flex;
        flex: 0 0 auto;

        img {
            border-radius: 50rem;
        }

        .verified {
            position: absolute;
            right: 0;
            bottom: 0;
            border: 2px solid $white;
            border-radius: 50rem;
            background: $white;
            color: $green;
        }
    }

    .content {
        flex-basis: 100%;
    }

    .date {
        float: right;
        color: $gray;
    }

    .stars {
        color: $yellow;
    }

    .name {
        font-weight: 700;
    }
}

.product-review-summary {
    .main {
        .result {
            font-size: 2.5rem;
            font-weight: 700;
        }

        .stars {
            color: $yellow;
            font-size: 1.5rem;
        }

        .number-opinions {
            color: $gray;
        }
    }

    .sub {
        @include media-breakpoint-up(md) {
            margin-left: 20px;
            border-left: 1px solid $gray;
            padding-left: 20px;
        }

        &:hover .item a {
            opacity: 0.5;
        }

        .item a {
            &:hover {
                color: $primary;
                opacity: 1;
            }
        }

        .score {
            display: inline-block;
            margin: 0 5px;
            width: 31px;
            font-size: 14px;
        }
    }

    .bar {
        display: inline-block;
        width: 100px;
        height: 10px;
        background: $light-blue;

        .in {
            display: block;
            width: 0;
            height: 100%;
            background: $primary;
        }
    }
}

.product-detail {
    a {
        color: $secondary;
    }

    .title {
        font-size: 1.5rem;
        font-weight: 700;
    }

    .price {
        .sale {
            display: inline-block;
            padding: 0 5px;
            background-color: $secondary;
            color: white;
            font-family: "Mulish", sans-serif;
            font-weight: 700;
        }

        .current {
            margin-top: 5px;
            font-size: 2.25rem;
            font-weight: 700;
        }

        .safe {
            margin-top: 5px;
            font-size: 80%;
            font-weight: 400;
        }
    }

    .summary-wrapper {
        p {
            margin: 0;
        }
    }

    .product-details-badges-wrapper {
        display: flex;
        flex-wrap: wrap;

        .product-details-badge {
            width: fit-content;
            font-size: 10px;
            background-color: var(--color_var);
            color: white;
            font-weight: bold;
            text-transform: uppercase;
            font-family: 'Mulish', sans-serif;
            margin-right: 5px;
            margin-bottom: 5px;
            line-height: 1.6;
        }
    }

    .float-select {
        position: relative;

        label {
            margin: 0;
            color: #b6b7c9;
            position: absolute;
            top: 0.625rem;
            left: 0.785rem;
            transition: 0.3s;
        }

        select.form-control {
            padding-top: calc((0.75rem * 1.8) + (0.375rem * 2));
            height: unset;
            appearance: none;
            background: $white url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iIzAwMDAwMCIgY2xhc3M9ImJpIGJpLWNoZXZyb24tY29tcGFjdC1kb3duIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEuNTUzIDYuNzc2YS41LjUgMCAwIDEgLjY3LS4yMjNMOCA5LjQ0bDUuNzc2LTIuODg4YS41LjUgMCAxIDEgLjQ0OC44OTRsLTYgM2EuNS41IDAgMCAxLS40NDggMGwtNi0zYS41LjUgMCAwIDEtLjIyMy0uNjd6Ii8+Cjwvc3ZnPg==") no-repeat;
            background-position: right 1rem center;
            transition: 0.3s;
        }
    }

    .product-sm {
        margin: 10px -5px 0;

        img {
            margin: 0 5px;
            cursor: pointer;
        }
    }

    .slick-dots {
        bottom: 20px;
    }
}

.contact {
    ol {
        margin-left: 27px;
        list-style-type: decimal-leading-zero;
    }
}

.table-basket {
    margin: 0;
    @include font-size(1rem);
    font-weight: 400;
    border-spacing: 0 5px;
    // border-spacing: 0;
    border-collapse: separate;
    white-space: nowrap;
    @include font-size(0.75rem);
    line-height: 1.2;

    thead {
        line-height: normal;
        vertical-align: bottom;
    }

    .product-image {
        display: block;
        width: 80px;
        height: 80px;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .product-name {
        white-space: wrap;
    }

    .product-number {
        margin-top: 5px;
        @include font-size(0.6875rem);
        opacity: 0.75;
    }

    .input-amount {
        display: inline-flex;
        align-items: flex-start;
        flex-wrap: nowrap;

        input {
            flex: 0 0 35px;
            border: 1px solid #b6b7c9;
            padding: 0 5px;
            width: 35px;
            @include font-size(0.75rem);
            line-height: 22px;
            text-align: center;
            appearance: none;
            -moz-appearance: textfield;
            font-weight: 700;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        .btn {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #b6b7c9;
            padding: 5px;
            width: 24px;
            height: 24px;
            @include font-size(0.75rem);
            text-align: center;
        }
    }

    th,
    td {
        padding: 10px;

        &:first-of-type {
            padding-left: 20px;
        }

        &:last-of-type {
            padding-right: 20px;
        }
    }

    .buttons {
        display: flex;

        .btn {
            padding: 0;
            @include font-size(0.75rem);
        }
    }

    @include media-breakpoint-down(md) {
        thead {
            display: none;
        }

        td {
            display: none;
        }

        .mobile {
            display: block;
        }
    }

    .mobile {
        display: flex;
        gap: 20px;
        padding: 20px;

        @include media-breakpoint-up(md) {
            display: none;
        }

        .product-image {
            flex: 0 0 auto;
        }
    }
}

.delivery-check {
    position: relative;
    // border: 1px solid #b6b7c9;
    margin: 0;
    padding: 0;
    // padding: 10px;
    width: 100%;
    height: 100%;
    align-items: unset;
    @include font-size(1rem);

    .form-check-input {
        position: absolute;
        top: 11px;
        left: 11px;
        margin: 0;
        border-radius: inherit;
    }

    .form-check-label {
        border: 1px solid #b6b7c9;
        padding: 10px;
        padding-left: 40px;
        width: 100%;
        min-height: 42px;
    }

    .price {
        float: right;
    }

    .title {
        display: block;
    }

    .dsc {
        display: block;
        @include font-size(0.875rem);
        font-weight: 400 !important;
    }
}

.progress-steps {
    position: relative;
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    width: 200px;
    pointer-events: none;

    .bar {
        position: absolute;
        top: 50%;
        right: 15px;
        left: 15px;
        height: 4px;
        background: $gray-200;
        transform: translateY(-50%);

        .bar-in {
            background: $primary;
            height: 4px;
        }
    }

    .step {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 4px solid $white;
        border-radius: 50rem;
        width: 38px;
        height: 38px;
        text-align: center;
        background: $gray-200;
        color: $primary;
        font-weight: 700;
        z-index: 1;

        &.active {
            background: $primary;
            color: $white;
        }
    }
}

.summary-box {
    position: sticky;
    top: 15px;
    border: 1px solid #b6b7c9;

    @include media-breakpoint-up(lg) {
        top: 115px;
    }

    section {
        &:not(:first-of-type) {
            border-top: 1px solid #b6b7c9;
        }
    }

    .form-check-input {
        border-radius: inherit;
    }
}

.add-product-code {
    .form-control {
        width: auto;

        @include media-breakpoint-up(lg) {
            width: 500px;
        }
    }
}

.chat {
    border: 1px solid #000;
    width: 100%;
    min-height: 400px;
    max-height: 450px;
    box-shadow: 1px 1px 3px 0px black;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .chat-window {
        height: 100%;
        overflow-y: scroll;
        margin-bottom: 15px;
    }

    .message {
        display: flex;
        position: relative;
        padding: 10px;
        font-size: 16px;

        >div {
            padding-bottom: 15px;
            padding-top: 8px;
        }

        img {
            border-radius: 50%;
            width: 40px;
            height: 40px
        }

        .time-right,
        .time-left {
            position: absolute;
            font-size: 14px;
            color: #9d9a9a;
        }

        .time-right {
            bottom: 3px;
            right: 10px;
        }

        .time-left {
            bottom: 3px;
            left: 10px;
        }

        &:not(:last-child) {
            margin-bottom: 15px;
        }

        &.consultant-message {
            border: 1px solid #dedede;
            background-color: #f1f1f1;
        }

        &.customer-message {
            border: 1px solid #ccc;
            background-color: #ddd;
            justify-content: flex-end;
        }
    }

    .input-wrapper {
        position: relative;

        .message-send-button {
            background-color: unset;
            border: none;
            position: absolute;
            bottom: 2px;
            right: 7px;
            cursor: pointer;

            &::before {
                content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktc2VuZCIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBkPSJNMTUuODU0LjE0NmEuNS41IDAgMCAxIC4xMS41NGwtNS44MTkgMTQuNTQ3YS43NS43NSAwIDAgMS0xLjMyOS4xMjRsLTMuMTc4LTQuOTk1TC42NDMgNy4xODRhLjc1Ljc1IDAgMCAxIC4xMjQtMS4zM0wxNS4zMTQuMDM3YS41LjUgMCAwIDEgLjU0LjExWk02LjYzNiAxMC4wN2wyLjc2MSA0LjMzOEwxNC4xMyAyLjU3NiA2LjYzNiAxMC4wN1ptNi43ODctOC4yMDFMMS41OTEgNi42MDJsNC4zMzkgMi43NiA3LjQ5NC03LjQ5M1oiLz4KPC9zdmc+);
            }
        }

        input {
            width: 100%;
            font-size: 16px;
        }
    }
}

.client-panel-nav {
    .list-group-item {
        border-radius: 0;
        font-size: 16px;
    }
}

// client panel
.tab-content {

    table {
        font-size: 14px;

        thead {
            text-transform: uppercase;
            font-weight: bold;

            @include media-breakpoint-down(lg) {
                display: none;
            }
        }

        tbody {

            th,
            td {
                font-weight: normal;
                text-transform: uppercase;
            }

            tr {

                @include media-breakpoint-down(xl) {
                    margin-bottom: 20px;
                }


                @include media-breakpoint-down(lg) {
                    display: block;

                    th,
                    td {
                        display: flex;
                        justify-content: space-between;
                    }

                    td[data-label]::before {
                        content: attr(data-label);
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

.collapse-table {
    background-color: $gray-100;

    .table {
        font-size: 11px;
        background-color: white;
    }

    thead th {
        font-weight: bold;
    }
}

.inventory-availability-indicator {
    line-height: 1;

    &:not(:last-child) {
        margin-bottom: 5px;
    }

    .indicator {
        display: flex;
        margin-bottom: 5px;

        div {
            border: 1px solid grey;
            height: 8px;
            width: 18px;
            display: inline-block;
            margin-right: 1px;
            background-color: white;
        }
    }

    span {
        font-size: 10px;
    }
}

.wishlist-table {
    button.add {
        padding: 6px 20px;
        font-size: 16px;

        i {
            font-size: 22px;
        }
    }

    td {
        vertical-align: middle;
    }
}

.my-orders-table tr {
    td a {

        text-decoration: underline;

        &::before {
            content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2hldnJvbi1kb3duIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEuNjQ2IDQuNjQ2YS41LjUgMCAwIDEgLjcwOCAwTDggMTAuMjkzbDUuNjQ2LTUuNjQ3YS41LjUgMCAwIDEgLjcwOC43MDhsLTYgNmEuNS41IDAgMCAxLS43MDggMGwtNi02YS41LjUgMCAwIDEgMC0uNzA4eiIvPgo8L3N2Zz4=);
        }
    }

    &.collapsed {
        td a {

            text-decoration: underline;

            &::before {
                content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2hldnJvbi1yaWdodCIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00LjY0NiAxLjY0NmEuNS41IDAgMCAxIC43MDggMGw2IDZhLjUuNSAwIDAgMSAwIC43MDhsLTYgNmEuNS41IDAgMCAxLS43MDgtLjcwOEwxMC4yOTMgOCA0LjY0NiAyLjM1NGEuNS41IDAgMCAxIDAtLjcwOHoiLz4KPC9zdmc+);
            }
        }
    }
}

.change-delivery-address-form {
    margin-bottom: 30px;

    input {
        margin-bottom: 5px;

        @include media-breakpoint-down(md) {
            margin-bottom: 10px;
        }
    }
}

// buying-from-us-page
.buying-from-us-page {
    .row {
        &-1 {
            .left-col {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            a {
                // max-width: 189px;
            }
        }

        &-2,
        &-4 {

            .left-col,
            .right-col {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                a {
                    max-width: 211px;
                }
            }
        }

        &-3 {
            color: $white;
            border-radius: 10px;
            overflow: hidden;

            .left-col {
                p {
                    font-weight: 300;
                }

                a {
                    width: 211px;
                }
            }

            .right-col {
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;

                    @include media-breakpoint-down(xl) {
                        max-height: 410px;
                    }
                }
            }

            @include media-breakpoint-up(lg) {

                .left-col,
                .right-col {
                    >div p:first-child {
                        min-height: 150px;
                    }
                }
            }
        }

        &-4 {
            img {
                object-fit: cover;
                width: 100%;
            }
        }
    }
}

.banner-subpage {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: fit-content;

    .background {
        position: relative;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        &:after {
            position: absolute;
            inset: 0;
            content: "";
            background: linear-gradient(90deg,
                    rgba(41, 33, 25, 0.7) 0%,
                    rgba(41, 33, 25, 0.6) 30%,
                    rgba(41, 33, 25, 0) 70%);
            // backdrop-filter: blur(5px);
        }

        img {
            object-fit: cover;
            min-height: 250px;
            width: 100%;

            @include media-breakpoint-down(lg) {
                object-position: right;
            }
        }
    }

    .content {
        position: absolute;
        width: fit-content;
        margin-bottom: 50px;

        @include media-breakpoint-up(sm) {
            left: 50px;
        }

        @include media-breakpoint-up(md) {
            left: 80px;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 100px;
            left: 100px;
        }

        @include media-breakpoint-up(xl) {
            left: 240px;
        }

        @include media-breakpoint-up(xxl) {
            left: 310px;
        }
    }

    &-center {
        align-items: center;

        .content {
            margin-bottom: 0;

            div>div>div {
                max-width: 369px;
            }

            .title-wrapper {
                max-width: unset;
            }
        }
    }

    &-no-img {
        min-height: 350px;
        flex-direction: column;

        .content {
            margin-bottom: 0;
            height: 100%;
            position: unset;

            div>div>div {
                max-width: 680px;
            }
        }
    }
}

.blue-icons-row {

    .blue-icon-box {
        position: relative;
        width: fit-content;
        text-align: center;

        @include media-breakpoint-up(md) {

            &.box-1,
            &.box-2,
            &.box-3 {
                margin: 0 auto;
            }
        }

        @include media-breakpoint-down(md) {
            margin: 0 auto;
        }
    }

    >div {
        @include media-breakpoint-up(md) {
            position: relative;

            // border-right: 4px solid $primary;
            &:not(:last-child) {
                &::before {
                    content: "";
                    position: absolute;
                    right: 0;
                    height: 100%;
                    width: 4px;
                    background-color: $primary;
                    border-radius: 5px;
                }
            }

        }

        &:last-child {
            border: none;

            &:before {
                display: none;
            }
        }

        @include media-breakpoint-down(md) {
            &:not(:last-child) {
                margin-bottom: 30px;
            }
        }
    }
}

.we-can-help-bottom {
    h4 {
        font-weight: bold !important;
    }

    .blue-icons {
        svg {
            margin-bottom: 20px;
            height: 50px;
        }

        >div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            @include media-breakpoint-down(md) {
                margin-bottom: 20px;
            }

            >div {
                display: flex;
                height: 45px;
                align-items: center;
            }
        }

        a {
            width: 229px;
        }

        .btn {
            height: 45px;
            width: 229px;
        }
    }
}

// about us page
.layout-with-menu-outside-of-container {
    .absolute-wrapper-for-sticky-menu {
        @include media-breakpoint-down(xxxl) {
            position: static;
            height: inherit;
        }
    }
}

.about-us-page {
    background-color: $gray-bg;

    .row {
        &-1 {
            .left-col {
                display: flex;
                margin-bottom: 30px;

                @include media-breakpoint-up(xxl) {
                    margin-bottom: 0;
                    align-items: center;
                }

                >div {
                    h1 {
                        font-size: 2.25rem;
                    }

                    @include media-breakpoint-up(xxl) {
                        max-width: 448px;
                    }
                }
            }
        }

        &-2 {
            .one-col {
                background-color: $light-blue;
                border-radius: 10px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 219px;

                >div {
                    max-width: 413px;
                }

                svg {
                    bottom: 0;
                    left: 0;

                    @include media-breakpoint-up(xl) {
                        left: 35px;
                    }

                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }

                .subtitles {
                    z-index: 2;

                    h2 {
                        font-weight: bold;
                    }

                    p {
                        font-size: 21px;

                        @include media-breakpoint-up(xs) {
                            font-size: 24px;
                        }
                    }
                }
            }
        }

        &-4 {
            p {
                font-size: 18px;
            }

            .left-col,
            .right-col {
                svg {
                    @include media-breakpoint-down(sm) {
                        width: 50px;
                    }
                }

                h5 {
                    @include media-breakpoint-down(sm) {
                        font-size: 18px;
                    }

                    font-weight: bold;
                }

                >div {
                    &:not(:last-child) {
                        margin-bottom: 17px;
                    }

                    p {
                        font-size: 16px;
                    }
                }
            }
        }

        &-5 {
            p {
                font-size: 18px;
            }

            .left-col {
                .quality-list {
                    li {
                        h6 {
                            font-weight: bold;
                        }

                        p {
                            font-size: 16px;
                            margin-bottom: 0;

                            @include media-breakpoint-up(xl) {
                                max-width: 448px;
                            }
                        }
                    }
                }
            }
        }

        a {
            width: fit-content;
        }
    }
}

.selling-to-us {
    .right-col {
        img {
            max-width: 530px;
            max-height: 300px;
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }

    .quick-wins {
        padding: 0 16px;

        @include media-breakpoint-up(md) {
            padding: 25px 25px;
        }

        @include media-breakpoint-up(lg) {
            padding: 35px 40px;
        }

        img {
            @include media-breakpoint-down(xs) {
                margin-bottom: 10px;
            }
        }

        .quality-list {
            @include media-breakpoint-down(md) {
                flex-direction: column;
            }

            flex-wrap: wrap;

            li.list-group-item {
                font-size: 22px;
                padding-left: 20px;
                font-weight: bold;

                @include media-breakpoint-down(lg) {
                    padding-left: 10px;
                }

                @include media-breakpoint-down(md) {
                    font-size: 20px;
                }

                &:not(:first-of-type) {
                    @include media-breakpoint-up(md) {
                        margin-left: 30px;
                    }

                    @include media-breakpoint-down(md) {
                        margin-top: 30px;
                    }
                }

                &::before {
                    transform: scale(2.2);
                }
            }
        }
    }
}

// news
.card-news {
    position: relative;
    border-radius: 10px;
    box-shadow: -0.8px 0.8px 16px 3px rgba($black, 0.1);
    overflow: hidden;

    .image {
        position: relative;
    }

    .category {
        position: absolute;
        top: 15px;
        left: 15px;
        border-radius: 50rem;
        padding: 3px 10px;
        min-width: 170px;
        font-size: 1.125rem;
        text-align: center;
        font-weight: 700;
        background: $orange;
        color: $purple;

        &.product-news {
            background: $orange;
            color: white;
        }

        &.customer-stories {
            color: $purple;
            background-color: $accent-light-blue;
        }

        &.company-news {
            background-color: $accent-mid-blue;
            color: white;
        }
    }

    .content {
        position: relative;
        padding: 15px 17px 15px;
        height: 100%;
    }

    .date {
        color: $blue;
        font-size: 1rem;
    }

    .title {
        @include truncate($font-size: 1.375rem,
            $line-height: 1.3,
            $lines-to-show: 3,
            $static-height: true);

        @include media-breakpoint-up(sm) {
            @include truncate($font-size: 1.375rem,
                $line-height: 1.3,
                $lines-to-show: 3,
                $static-height: true);
        }

        font-weight: 800;
    }
}

.news-nav {
    border-width: 2px;
    flex-wrap: nowrap;

    @include media-breakpoint-down(lg) {
        overflow-x: scroll;
    }


    .nav-item {
        min-width: 150px;

        @include media-breakpoint-down(lg) {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    .nav-link {
        padding: 15px 50px;

        @include media-breakpoint-down(lg) {
            padding-top: 3px;
            padding-bottom: 3px;
        }
    }

    li button {

        @include media-breakpoint-down(lg) {
            white-space: nowrap;
        }
    }
}

.card-news-big {
    position: relative;
    border-radius: 10px;
    box-shadow: -0.8px 0.8px 16px 3px rgba($black, 0.1);
    overflow: hidden;

    .image {
        position: relative;
    }

    .category {
        border-radius: 50rem;
        padding: 3px 10px;
        color: $purple;
        background: $orange;
        min-width: 170px;
        font-size: 1.375rem;
        text-align: center;
        font-weight: 800;
    }

    .content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 20px;
        height: 100%;

        @include media-breakpoint-up(lg) {
            padding: 20px 20px 20px 0;
        }
    }

    .date {
        color: $blue;
        font-size: 1.25rem;
    }

    .title {
        @include truncate($font-size: 1.875rem,
            $line-height: 1.3,
            $lines-to-show: 5,
            $static-height: true);
        font-weight: 800;

    }
}

.news-post {
    border-bottom: 1px solid $gray-ef;

    .date {
        color: $blue;
        font-size: 1.125rem;
    }

    .title {
        font-size: 2.25rem;
        line-height: 1.3;
        font-weight: 700;
    }

    p+img {
        margin: 40px 0;
    }

    .btn-gallery {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 260px;
        height: 58px;
        color: $white;
        background: $blue;
        font-size: 1rem;
        font-weight: 400;
    }
}

.news-post-more {
    .heading {
        font-size: 1.5rem;
        font-weight: 700;
    }
}

.our-partnerships-page {
    background-color: $gray-bg;

    .row {
        .row-header {
            @include media-breakpoint-up(md) {
                height: 95px;
            }

            h2 {
                margin: 0;

                @include media-breakpoint-down(xss) {
                    font-size: 22px;
                }
            }

            .producer-logo {
                @include media-breakpoint-down(lg) {
                    max-width: 150px;
                }

                @include media-breakpoint-down(sm) {
                    max-width: 130px;
                    max-height: 90%;
                }

                @include media-breakpoint-down(xs) {
                    max-width: 130px;
                    max-height: 85%;
                }

                @include media-breakpoint-down(xss) {
                    max-width: 110px;
                    max-height: 45px;
                }
            }
        }

        &-1 {
            .left-col {
                display: flex;
                align-items: center;
                justify-items: center;

                .title {
                    font-size: 2.125rem;
                }
            }
        }
    }
}

.delivery-and-installation-page {
    .delivery-option-description {
        max-width: 760px;
    }

    h4 {
        font-weight: bold;
    }
}

.deliver-option-listing-wrapper {
    .list-group li {
        margin-bottom: 25px;
    }

    .header {
        color: white;
        height: 56px;
        display: flex;


        .left-square {
            justify-content: center;
            min-width: 56px;
        }

        .left-square,
        .title {
            display: flex;
            align-items: center;
        }

        h6 {
            margin: 0;
            font-weight: 700;
        }
    }

    .title {
        background-color: $accent-mid-blue;
        width: 100%;
        padding-left: 34px;
    }

    .content {
        background-color: $light-blue;
        padding: 30px;


    }

    &-1 {
        .left-square {
            background-color: $accent-mid-blue-darker;
        }

        .title {
            background-color: $accent-mid-blue;
        }

        ul .list-group-item {
            font-size: 16px;

            &::before {
                content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cellipse cx='9.58606' cy='9.57346' rx='9.58606' ry='9.57346' fill='%23004986'/%3E%3Cg clip-path='url(%23clip0_2559_26128)'%3E%3Cpath d='M8.47401 14.3602L4.79297 10.6411L5.87428 9.54855L8.3283 12.028L13.1673 5.74414L14.379 6.69718L8.47401 14.3602Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2559_26128'%3E%3Crect width='9.58605' height='8.6161' fill='white' transform='translate(4.79297 5.74414)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
            }
        }
    }

    &-2 {
        .left-square {
            background-color: $blue-green-darker;

        }

        .title {
            background-color: $blue-green;
        }

    }

    &-3 {
        .left-square {
            background-color: $orange-darker;

        }

        .title {
            background-color: $orange;
        }

        ul .list-group-item {
            font-size: 16px;

            &::before {
                content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='10' cy='10' r='10' fill='%23ff9e1b'/%3E%3Cpath d='M8.84 15L5 11.1151L6.128 9.97392L8.688 12.5638L13.736 6L15 6.9955L8.84 15Z' fill='white'/%3E%3C/svg%3E%0A");
            }
        }
    }

    &-4 {
        .left-square {
            background-color: $accent-mid-blue-darker;

        }

        .title {
            background-color: $primary;
        }

        ul .list-group-item {
            font-size: 16px;

            &::before {
                content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cellipse cx='9.58606' cy='9.57346' rx='9.58606' ry='9.57346' fill='%230077CF'/%3E%3Cg clip-path='url(%23clip0_2559_26186)'%3E%3Cpath d='M8.47401 14.3602L4.79297 10.6411L5.87428 9.54855L8.3283 12.028L13.1673 5.74414L14.379 6.69718L8.47401 14.3602Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2559_26186'%3E%3Crect width='9.58605' height='8.6161' fill='white' transform='translate(4.79297 5.74414)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
            }
        }
    }
}

.delivery-and-installation-page-faq {
    .row .row {
        margin-bottom: 18px;

        .left-col {
            @include media-breakpoint-down(xl) {
                margin-bottom: 18px;
            }
        }

        h5 {
            font-weight: bold;
            margin-bottom: 18px;
        }
    }
}

.test-bakery-page {
    background-color: $gray-bg;

    .row {
        video {
            @include media-breakpoint-between(lg, xl) {
                height: auto !important;
            }
        }

        &-1 {
            h5 {
                font-weight: bold;
            }
        }

        &-2 {
            .left-col {
                display: flex;
                flex-direction: column;
                justify-content: center;

                @include media-breakpoint-up(xl) {
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                    // max-height: 305px;
                }

                @include media-breakpoint-down(xl) {
                    border-top-right-radius: 10px;
                    border-top-left-radius: 10px;
                }
            }
        }

        &-3 {
            .right-col {
                >div {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 24px 20px 24px 25px;
                    border-radius: 10px;
                    border-left: 10px solid #0077cf;
                    min-height: 125px;

                    p {
                        margin: 0;
                        font-size: 15px;
                    }
                }
            }
        }

        &-4 {
            .left-col {
                button {
                    max-width: 315px;
                    width: 100%;
                    height: 45px;
                }
            }

            .right-col {
                img {
                    object-fit: cover;

                    @include media-breakpoint-up(xl) {
                        height: 311px;
                    }
                }
            }
        }

        &-5 {
            --border-radius-value: 15px;

            .accordion {
                .accordion-item {
                    border: none;

                    .accordion-button {
                        background-color: $primary;
                        font-size: 18px;
                        font-weight: bold;
                        padding: 0 16px 0 0;
                        height: 59px;
                        color: white;

                        @include media-breakpoint-down(lg) {
                            font-size: 16px;
                        }

                        @include media-breakpoint-down(md) {
                            font-size: 18px;
                        }

                        @include media-breakpoint-down(xs) {
                            font-size: 16px;
                        }

                        @include media-breakpoint-down(xss) {
                            font-size: 14px;
                        }

                        >div {
                            height: 59px;
                            width: 59px;
                            background-color: $light-blue;
                            color: $primary;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 34px;

                            @include media-breakpoint-down(xl) {
                                margin-right: 20px;
                            }

                            @include media-breakpoint-down(lg) {
                                margin-right: 15px;
                            }

                            @include media-breakpoint-down(xs) {
                                margin-right: 10px;
                            }
                        }
                    }

                    .accordion-body {
                        padding-right: 0;
                        padding-left: 0;
                        padding-top: 35px;

                        @include media-breakpoint-up(md) {
                            max-width: 482px;
                        }

                        label {
                            font-size: 16px;
                        }

                        .form-control {
                            border-radius: 5px;

                            &::placeholder {
                                color: #a7a8a9;
                                line-height: 24px;
                                font-weight: 300;
                            }
                        }
                    }
                }

                #collapse3 {
                    textarea {
                        height: 179px;
                    }
                }

                #collapse4 {
                    .accordion-body {
                        padding-top: 29px;
                    }
                }

                .slot-date {
                    .form-control {
                        padding-left: 15px;
                        background: $primary;
                        color: #ffffff;
                        border: 1px solid $primary;
                        border-top-left-radius: var(--border-radius-value);
                        border-top-right-radius: var(--border-radius-value);
                        border-bottom-left-radius: 0 !important;
                        border-bottom-right-radius: 0 !important;
                        font-size: 18px;
                    }

                    ::-webkit-calendar-picker-indicator {
                        filter: invert(1);
                    }
                }

                .time-range-availability {
                    background-color: $primary;
                    color: $white;
                    border-bottom-right-radius: var(--border-radius-value);
                    border-bottom-left-radius: var(--border-radius-value);

                    .title {
                        font-size: 16px;
                        font-weight: 300;
                    }

                    .check-boxes-wrapper {
                        @include media-breakpoint-between(md, xl) {
                            flex-wrap: wrap;
                        }

                        @include media-breakpoint-down(xs) {
                            flex-direction: column;
                        }
                    }

                    .form-check,
                    .form-group {
                        display: flex;
                        align-items: center;

                        @include media-breakpoint-between(md, xl) {
                            margin-bottom: 10px;
                        }

                        @include media-breakpoint-down(xs) {
                            margin-bottom: 10px;
                        }

                        label {
                            margin-bottom: 0;
                            padding-left: 0;
                            color: inherit;
                            font-size: 18px;
                        }

                        .form-check-input {
                            border-radius: 4.5px;
                            height: 30px;
                            width: 30px;
                            margin-right: 5px;

                            &:checked {
                                background-color: $white;
                                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAyMCAyMCc+PHBhdGggZmlsbD0nbm9uZScgc3Ryb2tlPScjMDA3N2NmJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMycgZD0nTTYgMTBsMyAzbDYtNicvPjwvc3ZnPg==);
                            }

                            @include media-breakpoint-down(xxl) {
                                height: 25px;
                                width: 25px;
                            }

                            @include media-breakpoint-down(sm) {
                                height: 23px;
                                width: 23px;
                            }
                        }
                    }
                }
            }

            .accordion-form-btn {
                margin-top: 15px;
                width: 244px;
            }
        }

        &-6 {
            .left-col {
                @include media-breakpoint-down(xl) {
                    margin-bottom: 50px;
                }

                button {
                    @include media-breakpoint-up(xss) {
                        width: 278px;
                    }
                }
            }

            .right-col {
                button {
                    width: 211px;
                }
            }

            .left-col>div,
            .right-col>div {
                @include media-breakpoint-up(xl) {
                    min-height: 150px;
                }
            }
        }

        &.test-bakery-request {

            .accordion-body,
            .accordion-item {
                background-color: #E5F1FA;
            }

            .accordion-header .accordion-button>div {
                border-bottom: 1px solid $primary;
            }

            .accordion-button {
                border-bottom-right-radius: 0 !important;
            }
        }


    }
}

.contact-us-page,
.contact-page {
    .row-2>div>div {
        padding: 10px 45px;
        min-height: 190px;
        border-radius: 10px;

        &:first-child {
            @include media-breakpoint-down(lg) {
                margin-bottom: 20px;
            }
        }
    }

    .directions {

        h2,
        h3,
        h4 {
            color: $primary;
            font-size: $font-size-base;
            font-weight: 700;
            margin-bottom: 5px;
        }
    }

    .contact-icons {
        @include media-breakpoint-down(md) {
            * {
                font-size: 16px !important;
            }
        }

        svg {
            @include media-breakpoint-down(xs) {
                margin-bottom: 5px;
            }
        }
    }

    .breadcrumbs-wrapper {
        margin: 0 auto !important;
        padding-top: 30px !important;
        padding-bottom: 20px !important;
    }
}

.contact-us-page form {
    .form-check .form-label {
        font-weight: 400;
    }

    input {
        &:not(input[type='checkbox']) {
            background-color: #FCFCFC;
            border-color: #A7A8A9;
        }

        border-radius: 2px;
    }

    textarea {
        border-radius: 2px;
        background-color: #FCFCFC;
        border-color: #A7A8A9;
    }
}

.buying-from-us-gallery {
    >div {
        width: 50%;

        @include media-breakpoint-up(md) {
            width: 14.28%;
        }

        img {
            width: 100%;
        }
    }
}



.five-col-gallery {
    >div {
        width: 50%;

        @include media-breakpoint-up(md) {
            width: 25%;

            img {
                min-width: 110px;
                min-height: 95px;
            }
        }

        img {
            width: 100%;
            object-fit: cover;
            height: 100%;
            max-height: 150px;
        }
    }
}

.password-rules {
    ul.dotted-list {
        list-style: disc;

        li:not(:last-of-type) {
            margin-bottom: 4px;
        }
    }
}

.cookies {
    &__alert {
        position: fixed;
        z-index: 1040;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0;
        border: 1px solid transparent;
        border-width: 1 0 0;
        border-radius: 0;
        border-color: #c6c6c6;
        padding: 1rem 0;
        background-color: #d9d9d9;

        p {
            font-size: 0.75rem;
            margin-bottom: 0px;
        }

        .btn {
            padding: .375rem;
            width: 41px;
        }
    }
}

/**
 *  product page
 */

.product-page {
    .accordion-item {
        border: 0;
    }
}

.mb-testimonial {
    @include media-breakpoint-down(lg) {
        max-width: 75%;
        margin: 0 auto;
    }

    @include media-breakpoint-down(md) {
        max-width: 100%;
    }

    .description {
        font-size: 22px;

        @include media-breakpoint-down(sm) {
            font-size: 16px;
        }
    }

    .mb-logo {
        @include media-breakpoint-down(sm) {
            width: 43px;
        }

        max-width: 180px;
    }

    .mb-signature {
        font-size: 18px;

        @include media-breakpoint-down(sm) {
            font-size: 16px;
        }
    }
}

.product-details-wrapper {
    .size-title {
        font-size: 14px;

        @include media-breakpoint-up(md) {
            font-size: 12px;
        }
    }
}

.product-image-wrapper {
    .btn-primary {
        @include media-breakpoint-down(lg) {
            width: 100%;
        }
    }
}

.product-presentation {
    .image-box {
        @include media-breakpoint-up(xxl) {
            height: 100%;
        }

        button {
            &:first-child {
                font-size: 18px;

                @include media-breakpoint-up(sm) {
                    font-size: 16px;
                }
            }

            &:last-child {
                font-size: 16px;
            }
        }
    }

    .product-card-img-wrapper {
        border-radius: 10px;
        overflow: hidden;
        max-height: 584px;
        max-width: 520px;

        @include media-breakpoint-up(xl) {
            width: 520px;
        }

        .product-image {
            width: 100%;
        }
    }

    .product-logo {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-v2 {
            flex-direction: unset;
            justify-content: start;
        }

        .brand-logo {
            max-width: 120px;
        }

        .add-to-favorites-btn {
            @include media-breakpoint-between(md, lg) {
                left: 1vw;
                bottom: -1.1vh;
                // position: absolute;
            }
        }
    }

    .product-heading {
        p {
            margin-bottom: 10px;

            @include media-breakpoint-between(md, lg) {
                margin-bottom: 50px;
            }
        }

    }
}

.enquire-section,
.card-normal-product {
    .btn {
        width: 100%;
        max-width: 343px;
    }
}

.enquire-section {
    color: black;

    .shield-icon {
        @include media-breakpoint-down(md) {
            height: 30px;
        }
    }

    border-left: 1px solid $gray-ef;

    @include media-breakpoint-down(xl) {
        border-left: 0;
        border-bottom: 1px solid $gray-ef;
    }
}

.product-icons-row {
    .icons-row-element {
        border-radius: 10px;

        @include media-breakpoint-up(lg) {
            min-height: 165px;
            max-width: 165px;
        }

        &:hover {
            box-shadow: -1px 1px 20px 4px rgba(0, 0, 0, 0.12);
            min-height: unset;
            height: fit-content;
            // justify-content: center;
        }

        img {
            height: 45px;
            width: 45px;
        }

        .title {
            @include media-breakpoint-down(xs) {
                font-size: 12px !important;
            }
        }

        .subtitle {
            @include media-breakpoint-down(xs) {
                font-size: 16px !important;
            }
        }

        &-v2 {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            max-width: 206px;
            min-height: 162px;

            @include media-breakpoint-down(md) {
                flex-direction: row;
                justify-content: center;
                max-width: 100%;
                box-shadow: -1px 1px 20px 4px rgba(0, 0, 0, 0.12);
                margin: 13px 0;
                text-align: start;
                min-height: 107px;
            }

            img {
                height: 49px;
                width: unset;
            }
        }
    }
}

.product-overview {
    .product-requirements {
        .no-description {
            cursor: auto;

            &::after {
                display: none;
            }
        }
    }

    .download-brochure {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 50px;
        padding: 11.5px 40px;
        border-left: 10px solid $primary;
        border-radius: 10px;

        @include media-breakpoint-down(md) {
            padding: 20px 20px 17px 20px;
            flex-direction: column;
            align-items: flex-start;
        }

        .title {
            @include media-breakpoint-between(lg, xl) {
                margin-bottom: 5px;
            }
        }
    }
}

.advice-page {
    background-color: $gray-bg;

    .row {
        .row-header {
            min-height: 148px;

            @include media-breakpoint-up(lg) {
                max-height: 148px;
                height: 100%;
            }

            &>div {
                height: 100%;

                img {
                    @include media-breakpoint-down(xs) {
                        max-width: 190px;
                    }
                }
            }

            h2 {
                margin: 0;

                @include media-breakpoint-down(xss) {
                    font-size: 22px;
                }
            }

            .producer-logo {
                @include media-breakpoint-down(lg) {
                    max-width: 150px;
                }

                @include media-breakpoint-down(sm) {
                    max-width: 130px;
                    max-height: 90%;
                }

                @include media-breakpoint-down(xs) {
                    max-width: 130px;
                    max-height: 85%;
                }

                @include media-breakpoint-down(xss) {
                    max-width: 110px;
                    max-height: 45px;
                }
            }
        }

        .advicer-description {
            max-width: 427px;

            @include media-breakpoint-down(xss) {
                margin-bottom: 20px;
            }
        }

        .video-description {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }

        .videos-wrapper {
            video {
                height: 242px;
            }

            &>div {
                &:nth-child(n+8) {
                    display: block;
                }
            }

            &.hide-videos-elements {
                &>div {
                    &:nth-child(n+8) {
                        display: none;
                    }
                }
            }
        }

        &-1 {
            background-color: white;
        }
    }
}

.pagination {
    font-size: 1.125rem;
    font-weight: normal;
    line-height: 1.125rem;


    .page-link {

        @include media-breakpoint-up(sm) {
            margin: 0 10px;
        }

        border: 0;
        color: $black;
        background: transparent;

        @include media-breakpoint-down(md) {
            font-size: 1rem;
        }

        @include media-breakpoint-down(xs) {
            padding: 6px 8px;
        }

        @include media-breakpoint-down(xss) {
            padding: 6px 6px;
        }

        @include media-breakpoint-down(xxss) {
            padding: 6px 4.5px;
        }
    }

    .active .page-link {
        background: transparent;
        color: $primary;
        text-decoration: underline;
    }

    .prev,
    .next {

        display: list-item;

        .page-link {
            font-size: 1rem;
            background: transparent;
            color: $primary;
            text-decoration: underline;

            @include media-breakpoint-down(xss) {
                font-size: 0.875rem;
            }
        }

        &.disabled {
            .page-link {
                color: rgba($primary, 0.4);
            }
        }
    }

    .more {
        .page-link {
            pointer-events: none;
        }
    }

    .page-item:not(:first-child) .page-link {
        margin-left: 0;
    }
}

.custom_order {
    border: 2px solid $primary;
    border-radius: 10px;
    background: $white;
    position: relative;

    .title {
        margin-bottom: 0.5rem;
        font-size: 1.125rem;
        font-weight: bold;
    }

    .item {
        color: $primary;
        font-size: 1.125rem;
        font-weight: bold;

        .tax {
            font-size: 0.75rem;
            font-weight: normal;
        }
    }

    .btn {
        padding: 10px 30px;
        font-size: 1rem;
    }

    .absolute-el {
        color: $primary;
        background-color: $light-blue;
        border: 1px solid #D9EBF8;
        max-width: 141px;
        width: 100%;
        height: 32px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        @include media-breakpoint-up(sm) {
            margin-bottom: 0;
            position: absolute;
            right: 21px;
            top: 21px;
        }
    }

    .product-details {
        .title {
            max-width: 250px;
        }
    }
}

.inline-picker {

    @include media-breakpoint-up(xxxss) {
        --grid-auto-row-height: 40px;
    }

    @include media-breakpoint-up(xxss) {
        --grid-auto-row-height: 50px;
    }

    @include media-breakpoint-up(xl) {
        --grid-auto-row-height: 55px;
    }

    @include media-breakpoint-up(md) {
        max-width: 482px;
    }

    .tempus-dominus-widget {
        width: 100%;

        padding: 0;
        overflow: hidden;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border: 1px solid $primary;

        .calendar-header {
            background-color: $primary;
            color: $white;
            height: 65px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 22px;
            padding: 0 35px;

            i {
                font-size: 32px;
                margin-top: 5px;

                @include media-breakpoint-down(xs) {
                    font-size: 25px;
                }
            }
        }

        .date-container-days {
            padding: 10px;

            grid-auto-rows: var(--grid-auto-row-height);

            .today:before {
                bottom: 10px !important;
                right: 10px !important;

                @include media-breakpoint-down(sm) {
                    bottom: 6px;
                    right: 7px;
                }
            }

            .day {
                font-size: 24px;
                height: var(--grid-auto-row-height);
                width: var(--grid-auto-row-height);

                // @include media-breakpoint-down(lg) {
                //     max-height: 50px;
                //     max-width: 50px;
                // }

                // @include media-breakpoint-down(sm) {
                //     font-size: 18px;
                //     max-height: 40px;
                //     max-width: 40px;
                // }

                @include media-breakpoint-down(xs) {
                    font-size: 18px;
                }
            }
        }
    }
}

.order-summary {
    &-title {
        max-width: 190px;
    }

    .title {
        font-size: 14px;
    }

    .total-price {
        border-bottom: 1px solid #efefef;
        margin-bottom: 20px;
        padding: 20px 0;
    }

    &.order-details {
        .title {
            font-size: 18px;
            font-weight: bold;
        }

        .order-summary-title {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            line-height: 16px;

        }

        .btn {
            display: none;
        }

        .total-price {
            border-bottom: none !important;
            margin: 0;
            padding: 12px 0 0 0;

            span {
                @include media-breakpoint-up(sm) {
                    font-size: 25px;
                }

                .little-vat {
                    font-size: 12px;
                }
            }
        }

        .summary-item {
            img {
                max-width: 72px;
                max-height: 51px;
            }
        }

        .summary-item:not(:first-child) {
            padding: 20px 0;
        }

        .little-vat {
            margin-left: 5px;
        }
    }
}

.payment-method {
    p {
        margin-bottom: 15px;
    }
}

.checkout-section {
    .enquire-form-img {
        width: 68px;
    }

    button {
        font-size: 16px;
    }

    .btn-warning {
        height: 45px;

    }



    form {
        label {
            @include media-breakpoint-between(md, xl) {
                font-size: 12px;
            }

            @include media-breakpoint-between(xl, xxl) {
                font-size: 14px;
            }
        }
    }
}

.exporting-equipment {
    .row-3 {
        .blue-icons>div {
            @include media-breakpoint-down(sm) {
                font-size: 14px;
            }

            &:not(:last-child) {
                @include media-breakpoint-down(xss) {
                    margin-bottom: 20px;
                }
            }
        }
    }

    .row-4 {
        h5 {
            @include media-breakpoint-down(xs) {
                font-size: 16px;
            }
        }
    }
}

.page-contact {
    .row-2>div>div {
        padding: 10px 45px;
        min-height: 190px;
        border-radius: 10px;

        &:first-child {
            @include media-breakpoint-down(lg) {
                margin-bottom: 20px;
            }
        }
    }

    .contact-icons {
        @include media-breakpoint-down(md) {
            * {
                font-size: 16px !important;
            }
        }

        svg {
            @include media-breakpoint-down(xs) {
                margin-bottom: 5px;
            }
        }
    }
}

.order-history {
    max-width: 540px;

    .order-status {
        &.success {
            color: #10C55B;
        }
    }

    .images-wrapper {
        max-width: 300px;

        >div {
            display: flex;
            justify-content: space-between;

            @include media-breakpoint-down(xs) {
                flex-direction: column;
            }

            img {
                flex: 1;
                max-width: 140px;

                @include media-breakpoint-down(xs) {
                    max-width: unset;
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
        }
    }

    .buttons-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin-left: auto;

        @include media-breakpoint-up(lg) {
            align-items: center;
        }

        .btn.btn-warning {
            max-width: 180px;
            width: 100%;
        }
    }
}
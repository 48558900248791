body {
    font-family: "Sofia Pro", sans-serif;
    max-width: 2000px;
    margin: 0 auto;
}

img {
    max-width: 100%;
    height: auto;

    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;

    &:hover,
    &:focus,
    &:active {
        @include media-breakpoint-up(lg) {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }
    }
}

hr {
    color: #efefef;
    height: 2px;
}

ul.no-bullets {
    margin-bottom: 0;
    list-style-type: none;
    padding: 0;

    li {
        line-height: 26px;

        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }
}

p {
    strong {
        color: $primary;
    }
}

li::marker {
    font-size: 14px;
}

.ls {
    &-1 {
        letter-spacing: 0.5rem;
    }

    &-2 {
        letter-spacing: 0.25rem;
    }
}

.accordion-button::after {
    background-position: center;
}

.card-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

h1 {
    font-weight: 700;

    > img {
        display: inline;
        width: auto;
        height: 30px;
    }
}

.heading {
    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
    }

    .title {
        text-transform: uppercase;
        letter-spacing: 0.25rem;
        font-weight: 700;
    }

    .subtitle {
        display: flex;
        align-items: center;
        margin-top: 5px;

        @include media-breakpoint-up(lg) {
            margin-top: 0;
            margin-left: calc(20px - 0.25rem);
            border-left: 2px solid rgba(var(--primary-rgb), 20%);
            padding-left: 20px;
            min-height: 50px;
        }
    }

    a {
        font-weight: 700;
        text-decoration: none;
    }
}

.grid {
    display: grid;
    align-items: flex-end;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 1fr);
    gap: 20px 20px;
    grid-auto-flow: row;

    @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
    }

    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }

    @include media-breakpoint-up(xl) {
        grid-template-columns: 1fr 1.35fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    .ratio:nth-of-type(1) {
        grid-area: 1 / 1 / 2 / 2;

        @include media-breakpoint-up(sm) {
            grid-area: 1 / 1 / 2 / 2;
        }

        @include media-breakpoint-up(md) {
            grid-area: 1 / 1 / 2 / 2;
        }

        @include media-breakpoint-up(xl) {
            grid-area: 4 / 1 / 7 / 2;
        }
    }

    .ratio:nth-of-type(2) {
        grid-area: 2 / 1 / 3 / 2;

        @include media-breakpoint-up(sm) {
            grid-area: 1 / 2 / 2 / 3;
        }

        @include media-breakpoint-up(md) {
            grid-area: 1 / 2 / 2 / 3;
        }

        @include media-breakpoint-up(xl) {
            grid-area: 3 / 2 / 7 / 3;
        }
    }

    .ratio:nth-of-type(3) {
        grid-area: 3 / 1 / 4 / 2;

        @include media-breakpoint-up(sm) {
            grid-area: 2 / 1 / 3 / 2;
        }

        @include media-breakpoint-up(md) {
            grid-area: 1 / 3 / 2 / 4;
        }

        @include media-breakpoint-up(xl) {
            grid-area: 1 / 3 / 4 / 4;
        }
    }

    .ratio:nth-of-type(4) {
        grid-area: 4 / 1 / 5 / 2;

        @include media-breakpoint-up(sm) {
            grid-area: 2 / 2 / 3 / 3;
        }

        @include media-breakpoint-up(md) {
            grid-area: 2 / 1 / 3 / 2;
        }

        @include media-breakpoint-up(xl) {
            grid-area: 4 / 3 / 7 / 4;
        }
    }

    .ratio:nth-of-type(5) {
        grid-area: 5 / 1 / 6 / 2;

        @include media-breakpoint-up(sm) {
            grid-area: 3 / 1 / 4 / 2;
        }

        @include media-breakpoint-up(md) {
            grid-area: 2 / 2 / 3 / 3;
        }

        @include media-breakpoint-up(xl) {
            grid-area: 4 / 4 / 7 / 5;
        }
    }
}

.fit-image {
    &-cover {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;

        &-right {
            object-position: right;
        }
    }

    &-fill {
        height: 100%;
        width: 100%;
        object-fit: fill;
        object-position: center;
    }
}

.height-eq {
    @include media-breakpoint-up(lg) {
        min-height: 350px;
        height: 100%;
    }
}

.br-10 {
    border-radius: 10px !important;
    overflow: hidden;
}

.br-13 {
    border-radius: 13px !important;
}

.txt-limit {
    &-bakery {
        font-size: 18px;

        @include media-breakpoint-between(lg, xxl) {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            /* number of lines to show */
            line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }
}

//
// show/on on specific width
//
.d-none-on {
    &-359 {
        @media (max-width: 359px) {
            display: none;
        }
    }
}

.show-on {
    &-359 {
        display: none;

        @media (max-width: 359px) {
            display: block;
        }
    }
}

.btn-thin {
    border-width: 1px;
}

.w-unset {
    width: auto;
}

.mobile-color-title {
    @include media-breakpoint-down(lg) {
        color: $primary;
    }
}

.shadow-around {
    box-shadow: 0 0 16px -8px rgba(66, 68, 90, 1);
}

.rounded {
    border-radius: 10px;

    &-right {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;

        @include media-breakpoint-down(lg) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
        }
    }

    &-left {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;

        @include media-breakpoint-down(lg) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }
    }
}

.swiper-gray-element {
    opacity: 0.1;
    pointer-events: none;
}

.disabled {
    pointer-events: none;
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);

    &-v2 {
        opacity: 1;
        background-color: #C8C8C8;
        color: black;
        pointer-events: none;
    }
}

.br {
    &-machine-of-the-month {
        display: none;

        @include media-breakpoint-between(lg, xxl) {
            display: block;
        }

        @media (max-width: 540px) {
            display: block;
        }

        @media (max-width: 375px) {
            display: none;
        }
    }
}

.ls-0 {
    letter-spacing: 0 !important;
}

.line-height-0 {
    line-height: 0 !important;
}

.br-right-side {
    &-1 {
        @include media-breakpoint-up(xl) {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }

        @include media-breakpoint-down(xl) {
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
        }
    }
}

.min-height {
    &-305 {
        min-height: 305px;
    }
}

.absolute-wrapper-for-sticky-menu {
    position: absolute;
    height: 100%;
    left: -150px;

    @media (max-width: 1700px) {
        left: -120px;
    }
}

.sticky-ul {
    max-width: 215px;
    background-color: $white;
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
    top: 55px;
    position: sticky;
    border: 1px solid $gray-ef;
    border-radius: 10px;

    li {
        padding: 16.5px 10px;
        margin: 0 10px;
        cursor: pointer;

        a {
            color: $black;
            text-decoration: none;

            @include media-breakpoint-between(xxl, xxxl) {
                font-size: 14px !important;
            }
        }

        border-bottom: 1px solid $gray-ef;

        &.active {
            position: relative;

            &::before {
                position: absolute;
                content: "";
                background-color: $primary;
                height: 49px;
                width: 3px;
                bottom: 0px;
                left: -10px;
            }

            a {
                font-weight: bold;
            }
        }

        &:last-child {
            border: none;

            &::before {
                bottom: 5px;
            }
        }
    }

    &-v2 {
        overflow: hidden;

        li {
            margin: 0;

            &.active {
                background-color: $primary;
                color: white;

                a {
                    color: white;
                }

                &::before {
                    display: none;
                }
            }
        }
    }
}

.mobile-nav-accordion {
    .accordion-item {
        overflow: hidden;
        --accordion-button-height: 40px;
        max-width: 240px;
        margin-bottom: 40px;

        .accordion-button {
            position: relative;
            max-height: var(--accordion-button-height);

            &::after {
                margin: 0;
                position: absolute;
                top: 26%;
                right: 4.5%;
            }

            &:not(.collapsed)::before {
                background-color: $light-blue;
            }

            &::before {
                content: "";
                width: var(--accordion-button-height);
                height: var(--accordion-button-height);
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        .accordion-body {
            padding: 0px;

            .accordion-ul {
                border: 0;
                background-color: $white;
                padding-left: 0;
                list-style: none;
                margin-bottom: 0;

                li {
                    margin: 0 10px;
                    cursor: pointer;

                    a {
                        padding: 16.5px 10px;
                        padding-left: 20px;
                        color: $black;
                        text-decoration: none;
                        display: block;

                        @include media-breakpoint-between(xxl, xxxl) {
                            font-size: 14px !important;
                        }
                    }

                    border-bottom: 1px solid $gray-ef;

                    &.active {
                        position: relative;

                        &::before {
                            position: absolute;
                            content: "";
                            background-color: $primary;
                            height: 49px;
                            width: 3px;
                            bottom: 0px;
                            left: -10px;
                        }

                        a {
                            font-weight: bold;
                        }
                    }

                    &:last-child {
                        border: none;

                        &::before {
                            bottom: 5px;
                        }
                    }
                }

                .list-group-item {
                    &.active {
                        &::before {
                            left: -10px;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        top: 55px;
        position: sticky;

        .accordion-collapse {
            display: block;

            .accordion-ul {
                max-width: 215px;
                border: 1px solid $gray-ef;
                border-radius: 10px;
            }
        }

        .accordion-header {
            display: none;
        }
    }
}

.gray-bg {
    background-color: $gray-bg;
}

.fit-content {
    height: fit-content;
}

.thick-blue-border-left-blue-container {
    min-height: 50px;
    padding: 11.5px 40px;
    border-left: 10px solid #0077cf;
    border-radius: 10px;
    background-color: $light-blue;
}

.nav-tabs .nav-link {
    font-weight: bold;
    color: black;

    &.active,
    &:hover {
        background-color: var(--news-nav-bg);
        color: white !important;
    }
}

/**
 *  Global styles
 */

::selection {
    background-color: $primary;
    color: $white;
}

* {
    outline: none !important;
}

body {
    overflow-x: hidden;

    &.modal-open-2 {
        overflow-y: auto !important;
        overflow-x: hidden !important;
        padding-right: 0 !important;
    }
}

img {
    display: inline-block;
    height: auto;
    max-width: 100%;
}

ul,
ol {
    &.list-style-type-disc,
    &.list-style-type-decimal {
        margin-bottom: 1rem;
        padding-inline-start: 40px;
    }

    &.list-style-type-disc {
        list-style-type: disc;
    }

    &.list-style-type-decimal {
        list-style-type: decimal;
    }
}

a {
    transition: 0.2s ease-in-out;
}

.link {
    cursor: pointer;
    color: $primary;
    text-decoration: underline;
}

/**
 *  Grid helpers
 */
section {
    >.container {
        @include media-breakpoint-down(xs) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

.container {
    &.container-lg {
        @include media-breakpoint-up(lg) {
            max-width: map-get($container-max-widths, "lg");
        }
    }

    &.container-xxl {
        @include media-breakpoint-up(xxl) {
            max-width: map-get($container-max-widths, "xxl");
        }
    }
}

.row.row-10 {
    margin-left: -5px;
    margin-right: -5px;

    >div {
        padding-left: 5px;
        padding-right: 5px;
    }
}

.row.row-20 {
    margin-left: -10px;
    margin-right: -10px;

    >div {
        padding-left: 10px;
        padding-right: 10px;
    }
}

/**
 *  Social media icons
 */

.social-media {
    font-size: 0.875rem;
    width: 100%;
    justify-content: flex-end;
    max-width: 280px;

    >li {
        padding: 0 10px;

        @include media-breakpoint-down(sm) {
            padding: 0 10px;
        }

        img {
            padding-bottom: 2px;
        }
    }

    .user-controls {
        color: $gray-400;
        display: flex;

        .login-icon {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 120px;
        }

        .logout-user,
        .login-icon {
            display: inline-block;
            color: $gray-400;

            &:hover {
                color: $secondary;
            }
        }

        @include media-breakpoint-down(sm) {
            .login-icon span {
                display: none;
            }
        }
    }
}

.cursor-pointer {
    cursor: pointer;
    border-bottom: 1px solid transparent;

    &:not(a) {
        &:hover {
            border-bottom: 1px solid #cccccc;
        }
    }
}

.embed-container {
    position: relative;
    padding-bottom: 33%;
    height: 100%;
    min-height: 350px;

    @include media-breakpoint-down(lg) {
        padding-bottom: 57.5%;
    }

    overflow: hidden;
    max-width: 100%;

    &-v2 {
        @include media-breakpoint-down(xl) {
            padding-bottom: 57.5%;
        }
    }
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.parallax-container {
    position: relative;

    .bg-light-blue {
        width: 100vw;
        margin-left: calc(-50vw + 50% - 8px);
    }
}

.simple-select {
    position: relative;
    z-index: auto;
    display: inline-block;
    width: 100%;
    border-radius: $border-radius;
    cursor: pointer;

    .select-field {
        position: relative;
        transition: 0.3s ease;
        padding: $input-padding-y $input-padding-x;
        padding-right: 60px;
        border-radius: $border-radius;
        text-align: left;
        box-shadow: 0 0 0 2px transparent;

        &:focus,
        &:active,
        &:hover {
            box-shadow: 0 0 5px 2px rgba($white, 0.5);
        }

        &.active,
        &:active {
            box-shadow: 0 0 5px 2px rgba($white, 0.5);
        }

        &.selected-value {
            color: $black;
        }
    }
}

.flag-select-wrapper {
    max-width: 65px;
    min-height: 50px;
    height: 100%;
    width: 100%;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid #b6b7c9;
    position: relative;
    cursor: pointer;

    &::after {
        content: url("data:image/svg+xml,%3Csvg width='6' height='5' viewBox='0 0 6 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.150118 0.913699L2.68969 4.32673C2.76238 4.42442 2.90235 4.42442 2.97581 4.32673L5.51538 0.9137C5.60973 0.786428 5.52466 0.600002 5.37232 0.600002L0.293181 0.600001C0.140838 0.600001 0.0557731 0.786427 0.150118 0.913699Z' fill='black'/%3E%3C/svg%3E");
        position: absolute;
        z-index: 100;
        top: 41%;
        transform: translateY(-50%);
        right: 10px;
        pointer-events: none;
    }

    span {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.flag-select {
    display: none;
}

.flag-select-list {
    padding: 0 10px 10px 6px;
    position: absolute;
    top: 21%;
    // transform: translateY(-50%);
    background-color: white;
    left: 2px;

    li {
        cursor: pointer;
    }
}

.dispatch-text {
    font-size: 16px;
    color: $primary;
}

.overflow-wrap {
    &-anywhere {
        overflow-wrap: anywhere;
    }
}
.featured-items {

    .heart-icon {
        height: 45px;
    }

    .product-card {
        overflow: hidden;

        .product-card-img-wrapper {
            img {
                @include media-breakpoint-up(md) {
                    min-height: 296px;
                }
            }
        }

        button {
            letter-spacing: 0;
        }
    }
}

.product-finder-v2-title {
    font-size: 18px;
}

.breadcrumb {
    .breadcrumb-item {
        font-size: 14px;
        font-weight: 400;

        @include media-breakpoint-down(xs) {
            font-size: 10px;
        }

        a {
            color: black !important;
            text-decoration: none;
        }

        &.active {
            color: #0077cf;
            font-weight: 700;
        }
    }
}
form {
    .form-control {
        min-height: 46px;
    }
}

.filter {
    .form-select {
        border-radius: 10px !important;
        min-height: 50px;
        width: auto;
        border-color: #a7a8a9;
    }
}

.form-check-custom-radio {
    display: flex;

    // input {
    //     flex: 1;
    // }

    >div {
        max-width: 670px;
    }

    .form-check-input[type=radio] {
        height: 20px;
        width: 20px;
        margin-right: 23px;
        background-color: white;
        background-size: 29px;
        margin-top: 3px;
    }

    label {
        font-weight: bold;
        margin-bottom: 12px;

        svg {
            margin-left: 10px;
        }
    }

    .time-range-availability {
        max-width: 442px;
    }
}


label.required {
    &::after {
        content: '*';
        color: black;
    }
}

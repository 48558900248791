/* 
    Categories page sidebar styles
*/
.width-equalizer {
    background-color: $gray-100;
    width: calc(11vw);

    @media(max-width: 2300px) {
        width: calc(50vw - #{(map-get($grid-breakpoints, xxxl)/2)-185px});
    }

    @media(max-width: 1750px) {
        width: calc(50vw - #{(map-get($grid-breakpoints, xxxl)/2)-213px});
    }

    @include media-breakpoint-down(xxxl) {
        width: calc(50vw - #{(map-get($grid-breakpoints, xxl)/2)-80px});
    }

    @include media-breakpoint-down(xxl) {
        width: calc(50vw - #{(map-get($grid-breakpoints, xl)/2)-76px});
    }

    @include media-breakpoint-down(xl) {
        display: none;
    }
}

.sidebar-width-equalizer {
    width: calc(18vw);

    @media(max-width: 2300px) {
        width: calc(50vw - #{(map-get($grid-breakpoints, xxxl)/2)-154px});
    }

    @media(max-width: 1750px) {
        width: calc(50vw - #{(map-get($grid-breakpoints, xxxl)/2)-200px});
    }

    @include media-breakpoint-down(xxxl) {
        width: calc(50vw - #{(map-get($grid-breakpoints, xxl)/2)-200px});
    }

    @include media-breakpoint-down(xxl) {
        width: calc(50vw - #{(map-get($grid-breakpoints, xl)/2)-250px});
    }

    @include media-breakpoint-down(xl) {
        width: 100%;
    }
}

.categories-sidebar {
    height: 100%;
    opacity: 0;
    transition: opacity .2s linear;
    pointer-events: none;

    * {
        transition: opacity .2s linear;
        opacity: 0;
    }

    &.visible {
        pointer-events: auto;
        opacity: 1;

        * {
            opacity: 1;
        }
    }

    .sidebar-category-sublist {
        font-size: 14px;
        font-weight: 600;

        ul li {
            font-size: 16px;
            font-weight: 400;

            &:first-child {
                margin-top: 19px;
            }
        }

        div.toggle-wrapper {
            width: fit-content;
        }

        .has-sub-sub {
            cursor: pointer;
            line-height: 0;
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='rgba(0,119,207,1)' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3E%3C/svg%3E");
            transition: transform .35s ease;
            transform-origin: .5em 50%;
            position: absolute;
            top: 50%;
            right: -35px;
            transform: translate(0, -50%);

            &[aria-expanded="true"] {
                transform: rotate(90deg) translate(-50%, 0);
            }
        }
    }

    input {
        &:focus {
            box-shadow: none;
        }
    }

    .b-example-divider {
        flex-shrink: 0;
        width: 1.5rem;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.1);
        border: solid rgba(0, 0, 0, 0.15);
        border-width: 1px 0;
        box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1), inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
    }

    .bi {
        vertical-align: -.125em;
        pointer-events: none;
        fill: currentColor;
    }

    .dropdown-toggle {
        outline: 0;
    }

    .nav-flush {
        .nav-link {
            border-radius: 0;
        }
    }

    .sidebar-ul {
        button {
            text-align: left;
        }

        >li {
            border-bottom: 1px solid #D4E8F6;
            padding-top: 10px;
            padding-bottom: 15px;
        }
    }

    .btn-toggle {
        display: inline-flex;
        align-items: center;
        // padding: .25rem .5rem;
        padding: 0 .5rem;
        font-weight: 600;
        color: #000000;
        background-color: transparent;
        border: 0;
        // margin-bottom: 10px;

        &:hover,
        &:focus {
            color: rgba(0, 0, 0, 0.85);
            box-shadow: none;
        }

        &::before {
            width: 1.25em;
            line-height: 0;
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='rgba(0,119,207,1)' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3E%3C/svg%3E");
            transition: transform .35s ease;
            transform-origin: .5em 50%;
            margin-right: 10px;
        }

        &[aria-expanded="true"] {
            color: rgba(0, 0, 0, 0.85);

            &::before {
                transform: rotate(90deg);
            }
        }
    }

    .btn-toggle-nav {
        li {
            margin-bottom: 15px;
        }

        a {
            display: inline-flex;
            padding: .4rem .688rem;
            margin-top: .125rem;
            text-decoration: none;
            background-color: white;
            max-width: 173px;
            width: 100%;

            @include media-breakpoint-up(xxl) {
                margin-left: .5rem;
            }

            &:hover,
            &:focus,
            &:active {
                box-shadow: none;
                background-color: $primary;
                color: white;
            }

            &.active {
                background-color: $primary;
                color: white;
            }
        }
    }

    .scrollarea {
        overflow-y: auto;
    }

    .fw-semibold {
        font-weight: 600;
    }

    .lh-tight {
        line-height: 1.25;
    }
}

.apply {
    position: sticky;
    bottom: 20px;
    margin-top: 15px;
    text-align: right;
}

.btn-filter-desktop {
    position: relative;
    align-items: center;

    &::after {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg fill='%230077cf' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512'%3E%3C!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --%3E%3Cpath d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 16px;
        height: 16px;
        display: block;
        margin-left: 10px;
        color: $primary;
    }

    &.filters-hidden {
        &::after {
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_434_4436)'%3E%3Cpath d='M2.44442 5.74365H1.11108V16.0001H2.44442V5.74365Z' fill='%230077CF'/%3E%3Cpath d='M8.66671 13.1284H7.33337V16.0002H8.66671V13.1284Z' fill='%230077CF'/%3E%3Cpath d='M14.8889 7.79492H13.5555V16.0001H14.8889V7.79492Z' fill='%230077CF'/%3E%3Cpath d='M2.44444 0H1.11111V3.89744H0V5.12821H3.55556V3.89744H2.44444V0Z' fill='%230077CF'/%3E%3Cpath d='M8.66661 0H7.33328V11.2821H6.22217V12.5128H9.77772V11.2821H8.66661V0Z' fill='%230077CF'/%3E%3Cpath d='M14.8889 5.94872V0H13.5556V5.94872H12.4445V7.17949H16V5.94872H14.8889Z' fill='%230077CF'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_434_4436'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 16px;
            height: 16px;
            display: block;
            margin-left: 10px;
        }
    }
}

.btn-filter-mobile {
    align-items: center;
    display: flex;

    &::after {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_434_4436)'%3E%3Cpath d='M2.44442 5.74365H1.11108V16.0001H2.44442V5.74365Z' fill='%230077CF'/%3E%3Cpath d='M8.66671 13.1284H7.33337V16.0002H8.66671V13.1284Z' fill='%230077CF'/%3E%3Cpath d='M14.8889 7.79492H13.5555V16.0001H14.8889V7.79492Z' fill='%230077CF'/%3E%3Cpath d='M2.44444 0H1.11111V3.89744H0V5.12821H3.55556V3.89744H2.44444V0Z' fill='%230077CF'/%3E%3Cpath d='M8.66661 0H7.33328V11.2821H6.22217V12.5128H9.77772V11.2821H8.66661V0Z' fill='%230077CF'/%3E%3Cpath d='M14.8889 5.94872V0H13.5556V5.94872H12.4445V7.17949H16V5.94872H14.8889Z' fill='%230077CF'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_434_4436'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 16px;
        height: 16px;
        display: block;
        margin-left: 10px;
    }
}
// Color system ~6

// scss-docs-start gray-color-variables
$white: #fff !default;
$gray-unactive: #A7A8A9;
$gray-ef: #efefef !default;
$gray-bg: #fcfcfc !default;
$gray-100: #fdfdfd !default;
$gray-200: #e5e5e5 !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-850: #2f2f2f;
$gray-900: #212529 !default;
$black: #000 !default;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: ( //   "100": $gray-100,
    //   "200": $gray-200,
    //   "300": $gray-300,
    //   "400": $gray-400,
    //   "500": $gray-500,
    //   "600": $gray-600,
    //   "700": $gray-700,
    //   "800": $gray-800,
    "850": $gray-850 //   "900": $gray-900,,,,,
) !default;
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
$blue: #0077cf !default;
$light-blue: #E5F1FA !default;
$light-blue-2: #EFF5FA !default;
$indigo: #6610f2 !default;
$purple: #001a72;
$pink: #e5007d;
$red: #e03c31 !default;
$orange: #ff9e1b;
$orange-darker: #b97011;
$yellow: #ffbf3f;
$green: #10c55b;
$teal: #29b7a2;
$cyan: #0dcaf0 !default;
$nav-blue: #ecf5fa;
$progress: #10c55b;
$accent-light-blue: #00C1D4;
$accent-mid-blue: #004986;
$accent-mid-blue-darker: #00335E;
$blue-green: #00A5BD;
$blue-green-darker: #007D8F;

// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: ( //   "blue":       $blue,
    //   "indigo":     $indigo,
    "purple": $purple,
    //   "pink":       $pink,
    //   "red":        $red,
    //   "orange":     $orange,
    //   "yellow":     $yellow,
    //   "green":      $green,
    //   "teal":       $teal,
    //   "cyan":       $cyan,
    //   "white":      $white,
    "gray": $gray-600,
    "gray-bg": $gray-200,
    "gray-dark": $gray-800) !default;
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary: $blue !default;
$secondary: $light-blue !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;
$gray: $gray-100 !default;
$yellow: $yellow !default;
$purple: $purple !default;
$nav-blue: $nav-blue !default;
$gray-ef: $gray-ef;
$gray-200: $gray-200;
$progress: $progress;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: ("primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "white": $white,
    "light": $light,
    "dark": $dark,
    "gray": $gray,
    "orange": $orange,
    "yellow": $yellow,
    "purple": $purple,
    "nav-blue": $nav-blue,
    "gray-ef": $gray-ef,
    "progress": $progress,
    "light-blue": $light-blue,
    "light-blue-2": $light-blue-2,
    "gray-bg": $gray-bg,
    "accent-mid-blue": $accent-mid-blue,
    "blue-green": $blue-green,
    "gray-unactive": $gray-unactive,
) !default;
// scss-docs-end theme-colors-map

// Prefix for :root CSS variables ~356

// $variable-prefix:  !default;

// Spacing ~368

// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (0: 0,
    1: 1px,
    2: 2px,
    3: 3px,
    4: 4px,
    5: 5px,
    6: 6px,
    7: 7px,
    8: 8px,
    8_5: 8.5px,
    10: 10px,
    9: 9px,
    11: 11px,
    12: 12px,
    13: 13px,
    14: 14px,
    15: 15px,
    16: 16px,
    17: 17px,
    18: 18px,
    19: 19px,
    20: 20px,
    21: 21px,
    22: 22px,
    23: 23px,
    24: 24px,
    25: 25px,
    26: 26px,
    27: 27px,
    28: 28px,
    29: 29px,
    30: 30px,
    32: 32px,
    33: 33px,
    34: 34px,
    35: 35px,
    36: 36px,
    37: 37px,
    38: 38px,
    39: 39px,
    40: 40px,
    41: 41px,
    42: 42px,
    43: 43px,
    44: 44px,
    45: 45px,
    46: 46px,
    47: 47px,
    48: 48px,
    49: 49px,
    50: 50px,
    52: 52px,
    53: 53px,
    54: 54px,
    55: 55px,
    56: 56px,
    57: 57px,
    58: 58px,
    59: 59px,
    60: 60px,
    61: 61px,
    62: 62px,
    63: 63px,
    64: 64px,
    65: 65px,
    66: 66px,
    67: 67px,
    68: 68px,
    69: 69px,
    70: 70px,
    71: 71px,
    72: 72px,
    75: 75px,
    77: 77px,
    80: 80px,
    85: 85px,
    95: 95px,
    100: 100px,
    110: 110px,
    120: 120px,
    125: 125px,
    135: 135px,
    140: 140px,
    150: 150px,
    180: 180px,
    187: 187px,
) !default;

// border 
$border-color: $gray-ef !default;
$border-radius: 10px !default;
$border-radius-sm: 10px !default;
$border-radius-lg: 10px !default;
$border-radius-pill: 10px !default;

// Body ~400

$body-bg: $white !default;
$body-color: black;
$body-text-align: null !default;

// Paragraphs ~454

$paragraph-margin-bottom: 24px !default;

// Grid columns

$grid-gutter-width: 20px !default;

// Container padding ~508

$container-padding-x: 20px;

// scss-docs-start container-max-widths
$container-max-widths: (sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px,
) !default;

// Typography ~571
// $font-family-sans-serif: 'Sofia Pro', sans-serif;
// $font-family-base: $font-family-sans-serif;

$font-size-base: 1rem !default;
$font-size-base-h: 0.9375rem !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base-h * 2 !default;
$h3-font-size: $font-size-base * 1.875 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base * 1.125;
$h7-font-size: $font-size-base * 0.875;
$h8-font-size: $font-size-base * 0.75;
$fs22-font-size: $font-size-base * 1.375;

// Buttons ~768

$btn-padding-x: 30px;
$btn-font-size: 0.875rem;

$btn-padding-y-sm: 5px;
$btn-padding-x-sm: 20px;
$btn-font-size-sm: 0.875rem;

$btn-font-weight: 700;

$btn-border-radius: 50rem;
$btn-border-radius-sm: 50rem;
$btn-border-radius-lg: 50rem;

$form-select-indicator-color: $primary !default;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='4' d='M2 5l6 6 6-6'/></svg>") !default;

//
// Breakpoints
//
$grid-breakpoints: (xxs: 0,
    xxxss: 30px,
    xxss: 340px,
    xss: 375px,
    xs: 480px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
    xxxl: 1600px,
);

// scss-docs-start font-sizes
$font-sizes: (1: $h1-font-size,
    2: $h2-font-size,
    3: $h3-font-size,
    4: $h4-font-size,
    5: $h5-font-size,
    6: $h6-font-size,
    8: 8px,
    14: 14px,
    15: 15px,
    16: 16px,
    17: 17px,
    12: $h8-font-size,
    18: 18px,
    20: 20px,
    22: 22px,
    24: 24px,
    26: 26px,
    30: 30px,
    32: 32px,
    36: 36px,
) !default;
// accordion
$accordion-button-icon: url("data:image/svg+xml,%3Csvg width='22' height='13' viewBox='0 0 22 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.83265 12.5968L0.343249 3.1073C-0.114417 2.64963 -0.114417 1.90764 0.343249 1.45003L1.45004 0.343238C1.90692 -0.113646 2.6474 -0.114526 3.10536 0.341285L10.6613 7.86184L18.2172 0.341285C18.6752 -0.114526 19.4157 -0.113646 19.8725 0.343238L20.9793 1.45003C21.437 1.90769 21.437 2.64968 20.9793 3.1073L11.49 12.5968C11.0323 13.0544 10.2903 13.0544 9.83265 12.5968Z' fill='%230077CF'/%3E%3C/svg%3E");
$accordion-button-active-icon: url("data:image/svg+xml,%3Csvg width='22' height='13' viewBox='0 0 22 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.83265 12.5968L0.343249 3.1073C-0.114417 2.64963 -0.114417 1.90764 0.343249 1.45003L1.45004 0.343238C1.90692 -0.113646 2.6474 -0.114526 3.10536 0.341285L10.6613 7.86184L18.2172 0.341285C18.6752 -0.114526 19.4157 -0.113646 19.8725 0.343238L20.9793 1.45003C21.437 1.90769 21.437 2.64968 20.9793 3.1073L11.49 12.5968C11.0323 13.0544 10.2903 13.0544 9.83265 12.5968Z' fill='%230077CF'/%3E%3C/svg%3E");
$accordion-button-active-color: $primary !default;
$accordion-button-color: $primary !default;
$accordion-button-active-bg: none;

$accordion-button-focus-box-shadow: none;

// btn close
$btn-close-width: 14px !default;
$btn-close-height: $btn-close-width !default;
$btn-close-bg: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMyAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjc3OTYgMi42NTk5M0w4LjkzODE3IDYuNUwxMi43Nzk2IDEwLjM0MDFDMTIuOTE3MSAxMC40ODA0IDEyLjk5NDEgMTAuNjY5IDEyLjk5NDEgMTAuODY1NEMxMi45OTQxIDExLjA2MTkgMTIuOTE3MSAxMS4yNTA1IDEyLjc3OTYgMTEuMzkwOEwxMS4zODU2IDEyLjc4NTVDMTEuMjQ1MyAxMi45MjMgMTEuMDU2OCAxMyAxMC44NjA1IDEzQzEwLjY2NDEgMTMgMTAuNDc1NiAxMi45MjMgMTAuMzM1MyAxMi43ODU1TDYuNDk3MDMgOC45NDUzOUwyLjY1ODcxIDEyLjc4NTVDMi41MTg0OCAxMi45MjMgMi4zMjk5NSAxMyAyLjEzMzYgMTNDMS45MzcyNCAxMyAxLjc0ODcxIDEyLjkyMyAxLjYwODQ4IDEyLjc4NTVMMC4yMTQ0MzkgMTEuMzkwOEMwLjA3Njk4OTggMTEuMjUwNSAwIDExLjA2MTkgMCAxMC44NjU0QzAgMTAuNjY5IDAuMDc2OTg5OCAxMC40ODA0IDAuMjE0NDM5IDEwLjM0MDFMNC4wNTI3NiA2LjVMMC4yMTQ0MzkgMi42NTk5M0MwLjA3Njk4OTggMi41MTk2NCAwIDIuMzMxMDIgMCAyLjEzNDU3QzAgMS45MzgxMyAwLjA3Njk4OTggMS43NDk1MSAwLjIxNDQzOSAxLjYwOTIyTDEuNjA4NDggMC4yMTQ1MzZDMS43NDg3MSAwLjA3NzAyNDEgMS45MzcyNCAwIDIuMTMzNiAwQzIuMzI5OTUgMCAyLjUxODQ4IDAuMDc3MDI0MSAyLjY1ODcxIDAuMjE0NTM2TDYuNDk3MDMgNC4wNTQ2MUwxMC4zMzUzIDAuMjE0NTM2QzEwLjQ3NTYgMC4wNzcwMjQxIDEwLjY2NDEgMCAxMC44NjA1IDBDMTEuMDU2OCAwIDExLjI0NTMgMC4wNzcwMjQxIDExLjM4NTYgMC4yMTQ1MzZMMTIuNzc5NiAxLjYwOTIyQzEyLjg0OTQgMS42Nzc3NSAxMi45MDQ4IDEuNzU5NSAxMi45NDI3IDEuODQ5N0MxMi45ODA1IDEuOTM5OSAxMyAyLjAzNjc1IDEzIDIuMTM0NTdDMTMgMi4yMzI0IDEyLjk4MDUgMi4zMjkyNCAxMi45NDI3IDIuNDE5NDVDMTIuOTA0OCAyLjUwOTY1IDEyLjg0OTQgMi41OTE0IDEyLjc3OTYgMi42NTk5M1oiIGZpbGw9IiMwMDc3Q0YiLz4KPC9zdmc+Cg==") !default;

// form input styles
$form-check-input-checked-color: $white;
$input-border-color: $gray-unactive;
$input-font-size: 13px;

$form-check-input-width: 1.572em;
$form-check-input-border-radius: 0.188em;

$input-padding-y: .891rem;

$input-border-radius: 5px;

$form-label-color: black;

// form select
$form-select-indicator: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAyMiAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuODMyNjUgMTIuNTk2OEwwLjM0MzI0OSAzLjEwNzNDLTAuMTE0NDE3IDIuNjQ5NjMgLTAuMTE0NDE3IDEuOTA3NjQgMC4zNDMyNDkgMS40NTAwM0wxLjQ1MDA0IDAuMzQzMjM4QzEuOTA2OTIgLTAuMTEzNjQ2IDIuNjQ3NCAtMC4xMTQ1MjYgMy4xMDUzNiAwLjM0MTI4NUwxMC42NjEzIDcuODYxODRMMTguMjE3MiAwLjM0MTI4NUMxOC42NzUyIC0wLjExNDUyNiAxOS40MTU3IC0wLjExMzY0NiAxOS44NzI1IDAuMzQzMjM4TDIwLjk3OTMgMS40NTAwM0MyMS40MzcgMS45MDc2OSAyMS40MzcgMi42NDk2OCAyMC45NzkzIDMuMTA3M0wxMS40OSAxMi41OTY4QzExLjAzMjMgMTMuMDU0NCAxMC4yOTAzIDEzLjA1NDQgOS44MzI2NSAxMi41OTY4WiIgZmlsbD0iIzAwNzdDRiIvPgo8L3N2Zz4K");
$form-select-bg-size: auto !default;


$form-select-font-size: 14px;
$form-select-color: black;
// radio styles
// $form-check-radio-border-radius: 2px;
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$primary}'/></svg>") !default;

// breadcrumb divider

$breadcrumb-divider: quote("|") !default;

// modal padding
$modal-inner-padding: 1.25rem !default;

// hr styles
$hr-height: 1px;
$hr-opacity: 1;
$hr-color: #EFEFEF !important;
// font weight
$font-weight-bolder: 800;

// offcanvas
$offcanvas-horizontal-width: 500px;

// nav tabs
$nav-tabs-border-width: 0;

// root
:root {
    --bs-purple: #001a72;
    --bs-gray: #6c757d;
    --bs-gray-bg: #e5e5e5;
    --bs-gray-dark: #343a40;
    --bs-gray-850: #2f2f2f;
    --bs-primary: #0077cf;
    --bs-secondary: #E5F1FA;
    --bs-success: #95c11f;
    --bs-info: #0dcaf0;
    --bs-warning: #ffbf3f;
    --bs-danger: #e03c31;
    --bs-white: #fff;
    --bs-light: #fdfdfd;
    --bs-dark: #212529;
    --bs-gray: #fdfdfd;
    --bs-orange: #ff9e1b;
    --bs-yellow: #ffbf3f;
    --bs-purple: #001a72;
    --bs-nav-blue: #ecf5fa;
    --bs-gray-ef: #efefef;
    --bs-progress: #10c55b;
    --bs-light-blue: #E5F1FA;
    --bs-light-blue-2: #EFF5FA;
    --bs-gray-bg: #fcfcfc;
    --bs-accent-mid-blue: #004986;
    --bs-blue-green: #00A5BD;
    --bs-gray-unactive: #A7A8A9;
    --bs-primary-rgb: 0, 119, 207;
    --bs-secondary-rgb: 229, 241, 250;
    --bs-success-rgb: 149, 193, 31;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 191, 63;
    --bs-danger-rgb: 224, 60, 49;
    --bs-white-rgb: 255, 255, 255;
    --bs-light-rgb: 253, 253, 253;
    --bs-dark-rgb: 33, 37, 41;
    --bs-gray-rgb: 253, 253, 253;
    --bs-orange-rgb: 255, 158, 27;
    --bs-yellow-rgb: 255, 191, 63;
    --bs-purple-rgb: 0, 26, 114;
    --bs-nav-blue-rgb: 236, 245, 250;
    --bs-gray-ef-rgb: 239, 239, 239;
    --bs-progress-rgb: 16, 197, 91;
    --bs-light-blue-rgb: 229, 241, 250;
    --bs-light-blue-2-rgb: 239, 245, 250;
    --bs-gray-bg-rgb: 252, 252, 252;
    --bs-accent-mid-blue-rgb: 0, 73, 134;
    --bs-blue-green-rgb: 0, 165, 189;
    --bs-gray-unactive-rgb: 167, 168, 169;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 0, 0, 0;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: black;
    --bs-body-bg: #fff;
}

$btn-disabled-opacity: .3 !default;


$transition-collapse:         height .70s ease;

// accordion
$accordion-button-icon: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAyMiAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuODMyNjUgMTIuNTk2OEwwLjM0MzI0OSAzLjEwNzNDLTAuMTE0NDE3IDIuNjQ5NjMgLTAuMTE0NDE3IDEuOTA3NjQgMC4zNDMyNDkgMS40NTAwM0wxLjQ1MDA0IDAuMzQzMjM4QzEuOTA2OTIgLTAuMTEzNjQ2IDIuNjQ3NCAtMC4xMTQ1MjYgMy4xMDUzNiAwLjM0MTI4NUwxMC42NjEzIDcuODYxODRMMTguMjE3MiAwLjM0MTI4NUMxOC42NzUyIC0wLjExNDUyNiAxOS40MTU3IC0wLjExMzY0NiAxOS44NzI1IDAuMzQzMjM4TDIwLjk3OTMgMS40NTAwM0MyMS40MzcgMS45MDc2OSAyMS40MzcgMi42NDk2OCAyMC45NzkzIDMuMTA3M0wxMS40OSAxMi41OTY4QzExLjAzMjMgMTMuMDU0NCAxMC4yOTAzIDEzLjA1NDQgOS44MzI2NSAxMi41OTY4WiIgZmlsbD0iIzAwNzdDRiIvPgo8L3N2Zz4K") !default;
$accordion-button-active-icon: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAyMiAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuODMyNjUgMTIuNTk2OEwwLjM0MzI0OSAzLjEwNzNDLTAuMTE0NDE3IDIuNjQ5NjMgLTAuMTE0NDE3IDEuOTA3NjQgMC4zNDMyNDkgMS40NTAwM0wxLjQ1MDA0IDAuMzQzMjM4QzEuOTA2OTIgLTAuMTEzNjQ2IDIuNjQ3NCAtMC4xMTQ1MjYgMy4xMDUzNiAwLjM0MTI4NUwxMC42NjEzIDcuODYxODRMMTguMjE3MiAwLjM0MTI4NUMxOC42NzUyIC0wLjExNDUyNiAxOS40MTU3IC0wLjExMzY0NiAxOS44NzI1IDAuMzQzMjM4TDIwLjk3OTMgMS40NTAwM0MyMS40MzcgMS45MDc2OSAyMS40MzcgMi42NDk2OCAyMC45NzkzIDMuMTA3M0wxMS40OSAxMi41OTY4QzExLjAzMjMgMTMuMDU0NCAxMC4yOTAzIDEzLjA1NDQgOS44MzI2NSAxMi41OTY4WiIgZmlsbD0iIzAwNzdDRiIvPgo8L3N2Zz4K") !default;
$accordion-icon-transform:                rotate(-180deg) !default;
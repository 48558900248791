#mobileNavAccordion {
    .accordion-item {
        overflow: hidden;
        --accordion-button-height: 40px;
        max-width: 240px;
        margin-bottom: 40px;

        .accordion-button {
            position: relative;
            max-height: var(--accordion-button-height);

            &::after {
                margin: 0;
                position: absolute;
                top: 26%;
                right: 4.5%;
            }

            &:not(.collapsed)::before {
                background-color: $light-blue;
            }

            &::before {
                content: "";
                width: var(--accordion-button-height);
                height: var(--accordion-button-height);
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        .accordion-body {
            padding: 0px;

            .sticky-ul {
                border: 0;

                .list-group-item {
                    &.active {
                        &::before {
                            left: -10px;
                        }
                    }
                }
            }
        }
    }
}
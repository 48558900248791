.btn {
    letter-spacing: 0;

    &-red {
        display: flex;
        align-items: center;
        border-color: $red;
        border-width: 1px;
        color: $red;
        font-weight: bold;

        &:hover {
            color: $red;
        }

    }

    &-yellow {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: $yellow;
        color: black;
        border: 0;
        padding: 10px 20px;
        font-size: 1rem;

        &:hover,
        &:active,
        &:focus {
            background-color: #EFAD28 !important;
        }
    }

    &-primary {
        border-color: $primary;
        background-color: $primary;
        color: white;
        padding: 7px 10px;
        font-size: 16px;

        &:hover,
        &:active,
        &:focus {
            background-color: transparent;
            border-color: $primary;
            color: $primary;
        }

        &:active,
        &:focus {
            box-shadow: 0 0 0 0 $primary;
        }

        path {
            transition: .2s;
        }

        &:hover svg,
        &:hover svg path {
            fill: $primary;
        }

        &-featured-items {
            width: 150px;
            height: 45px;

            &:hover,
            &:active,
            &:focus {
                background-color: #015FA5;
                color: white !important;
            }
        }


        &-v2 {
            color: $primary;
            background-color: transparent;
            border: 2px solid;
            border-color: $primary;
            letter-spacing: 0;
            min-width: 122px;
            transition: $transition-base;
            transition-timing-function: unset;

            path {
                transition: .2s;
            }

            &:hover,
            &:active,
            &:focus {
                background-color: $primary;
                border-color: $primary;
                color: white;
                box-shadow: none;
            }

            &:hover svg,
            &:hover svg path {
                fill: white;
            }

        }

    }

    &-white {
        padding: 10px 30px;
        border: 2px solid;
        border-color: white;
        background-color: transparent;
        color: white;
        transition: $transition-base;
        font-size: 1rem;

        &:hover,
        &:active,
        &:focus {
            background-color: white;
            color: $primary;
            box-shadow: 4px 4px 0px 0px var(--white);
        }

        &:active,
        &:focus {
            box-shadow: 0 0 0 0 var(--white);
        }
    }

    &-accordion {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        width: 100%;
        color: $primary;
        font-size: 1.25rem;
        font-weight: 400;
        text-align: left;
        text-transform: none;
        letter-spacing: 0;
        box-shadow: none;

        .icon {
            flex: 0 0 auto;
            margin-left: 20px;
            width: 20px;
            height: 20px;
            transform: rotate(45deg);
            fill: $primary;
            transition: $transition-base;
        }

        &.collapsed .icon {
            transform: rotate(0);
        }
    }

    &-icon1 {
        margin: 0;
        border: 0;
        padding: 0;
        stroke: var(--white);
        fill: transparent;
        transition: $transition-base;

        &:hover {
            fill: var(--white);
        }
    }
}

.add-to-favorites-btn {
    text-decoration: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg {
        margin-right: 10px;
    }

    &:focus {
        &:hover {
            transform: scale(1);
        }
    }

    img {
        &:hover {
            transform: scale(1);
        }
    }

    &-border {
        border: 1px solid #E03C31;
        border-radius: 50%;
        width: 45px;
        height: 45px;

        svg {
            margin: 0;
        }
    }
}
/**
 * Header styles
 */

header {
    border-bottom: 2px solid #efefef;
    z-index: 1035;
    position: relative;
}

header .form-control {
    border: 0;

    @include media-breakpoint-down(md) {
        font-size: 15px;
    }

    &::placeholder {
        color: #b5b4b4;
    }
}

.search-form-container {

    @include media-breakpoint-down(sm) {
        padding: 0;
    }
}

form.search-form {
    z-index: 9999;
    width: 100%;

    input.form-control {
        border: 2px solid $primary;
        transition: 0.3s;
        background-color: white;

        &::placeholder {
            // color: black;
        }

        @include media-breakpoint-down(lg) {
            right: unset;
            top: unset;
            left: 0;
            bottom: -19px;
            height: 30px;
        }
    }
}

.header-logo {
    @include media-breakpoint-down(sm) {
        width: 130px;
    }
}

.cart-items-counter {
    position: absolute;
    background-color: $primary;
    top: 12px;
    right: -13px;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 600;
    font-size: 12px;
    z-index: 1000;

    @include media-breakpoint-down(lg) {
        display: none;
    }
}

.navigation {
    @include media-breakpoint-up(xl) {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 1000;
    }

    .dropdown-menu {
        min-width: 230px;
    }
}

.nav-top {
    font-size: 0.75rem;

    .btn-link {
        font-size: 0.75rem;

        @include media-breakpoint-up(md) {
            font-size: 0.875rem;
        }
    }
}

.nav-bottom {
    .logo {
        &-mobile {
            max-height: 43px;
        }
    }
}

.navbar-menu {
    .nav-item {
        padding: 0.5rem 1rem;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            width: 0%;
            background-color: $secondary;
            height: 3px;
            transition: 0.1s ease-out;
        }

        &:hover,
        &:active,
        &:focus {
            .nav-link {
                color: $primary;
            }

            &:before {
                width: 88%;
            }
        }

        &.active {
            color: $primary;

            &:before {
                content: "";
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
                width: 88%;
                background-color: $secondary;
                height: 3px;
                transition: 0.1s ease-out;
            }

            .nav-link {
                color: $primary;
            }
        }
    }

    .nav-link {
        padding: 0 !important;
        color: $gray-500;
        font-size: 1rem;
        font-weight: 800;
        text-transform: uppercase;

        @include media-breakpoint-up(lg) {
            transition: 0.2s ease-out;

            &:hover,
            &:focus,
            &:focus:active,
            &:active {
                &:before {
                    width: 100%;
                }
            }
        }
    }

    &-2 {
        flex-direction: row;

        .nav-item {
            padding-right: 10px;
            padding-left: 10px;
            padding-bottom: 0;

            &:hover {
                color: $secondary;
            }

            .nav-link {
                color: $white;

                @include media-breakpoint-up(xl) {
                    font-size: 18px;
                }

                &:hover {
                    color: $secondary;
                }
            }
        }
    }
}

/**
 *  Btn Toggle
 */

.btn-toggle {
    width: 26px;
    height: 16px;
    display: block;
    position: relative;
    cursor: pointer;
    border: 0;
    transition: 0.5s ease-in-out;

    &.open {
        span {
            height: 3px;

            &:nth-child(1),
            &:nth-child(6) {
                transform: rotate(45deg);
            }

            &:nth-child(2),
            &:nth-child(5) {
                transform: rotate(-45deg);
            }

            &:nth-child(1) {
                left: 0px;
                top: 4px;
            }

            &:nth-child(2) {
                left: calc(50% - 5px);
                top: 4px;
            }

            &:nth-child(3) {
                left: -50%;
                opacity: 0;
            }

            &:nth-child(4) {
                left: 100%;
                opacity: 0;
            }

            &:nth-child(5) {
                left: 0px;
                top: 12px;
            }

            &:nth-child(6) {
                left: calc(50% - 5px);
                top: 12px;
            }
        }
    }

    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 50%;
        background: white;
        opacity: 1;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;

        .active & {
            background: $white;
        }

        &:nth-child(even) {
            left: 50%;
        }

        &:nth-child(odd) {
            left: 0px;
        }

        &:nth-child(1),
        &:nth-child(2) {
            top: 0px;
        }

        &:nth-child(3),
        &:nth-child(4) {
            top: 7px;
        }

        &:nth-child(5),
        &:nth-child(6) {
            top: 14px;
        }
    }
}

/**
 *  Navigation for mobile
 */

.nav-bottom .navbar-collapse {
    @include media-breakpoint-down(md) {
        margin: 0 -15px;

        &.show,
        &.collapsing {
            box-shadow: 0px 12px 4px 0px rgba(0, 0, 0, 0.18);
        }
    }
}

// client panel modal menu

.menu-left {
    .modal-body {
        font-size: 1.2em;
        padding: 10px;

        @include media-breakpoint-down(md) {
            font-size: 1em;
            font-weight: bold;
        }

        i {
            @include media-breakpoint-down(md) {
                font-weight: bold;
            }
        }
    }

    .modal-dialog {
        position: fixed;
        margin: auto;
        width: 100%;
        max-width: 360px;
        height: auto;
        transform: translate3d(0%, 0, 0) !important;
        top: 8px;
        left: -488px;
        background-color: $white;
        transition: left 0.3s ease-out !important;

        @include media-breakpoint-down(sm) {
            top: 20px;
        }
    }

    &.show .modal-dialog {
        left: 0;
    }
}

.collapsing {
    transition: 0.125s;
}

.logins {
    @include media-breakpoint-up(lg) {
        position: absolute;
        right: 30px;
        top: -40px;
    }

    @include media-breakpoint-up(xl) {
        right: 55px;
    }

    @include media-breakpoint-up(xxxl) {
        right: 0;
    }

    order: 6;

    @include media-breakpoint-down(lg) {
        .sign-in {
            display: none;
        }

        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    a {
        text-decoration: none;
        color: black;
    }

    .login-icon {
        @include media-breakpoint-down(lg) {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

// .offcanvas-logins {
//     display: flex;
//     justify-content: space-between;
//     width: 90%;
//     padding-top: 20px;
//     padding-bottom: 20px;

//     a {
//         color: black;
//         &:first-child {
//             order: 1;
//         }
//     }
// }
header {
    @include media-breakpoint-down(lg) {
        background-color: $secondary;
    }
}
.header {
    &-search-wrapper {
        z-index: 10;

        input {
            right: 85px;
            top: 26px;
            border: 2px solid $primary;
            transition: 0.3s;
            background-color: white;

            @include media-breakpoint-down(lg) {
                right: unset;
                top: unset;
                left: 0;
                bottom: -19px;
                height: 30px;
            }
        }
    }

    &-blue-top-bar {
        height: 55px;
        font-size: 0.875rem;

        @include media-breakpoint-up(xl) {
            font-size: 1rem;
        }
    }

    &-navbar-wrapper {
        height: 100px;

        .navbar {
            position: unset;
        }

        @include media-breakpoint-down(lg) {
            height: 90px;
        }

        .navbar-collapse {
            z-index: 100;

            @include media-breakpoint-down(lg) {
                position: absolute;
                top: 88px;
                left: 0;
                height: 100%;
            }

            @include media-breakpoint-down(sm) {
                width: 100vw;
            }

            .nav-item {
                height: 100%;

                &:hover {
                    background-color: $secondary;
                }

                &:first-child {
                    background-color: $secondary;
                    font-weight: bold;

                    &:hover {
                        .megamenu {
                            display: flex;
                        }
                    }
                }

                &:not(:first-child):hover {
                    .little-menu {
                        @include media-breakpoint-up(lg) {
                            display: block;
                        }
                    }
                }

                &.active {
                    background-color: $secondary;
                    font-weight: bold;
                }

                @include media-breakpoint-up(lg) {
                    &:hover {
                        background-color: $secondary;
                        position: relative;

                        &::before {
                            content: "";
                            position: absolute;
                            width: 100%;
                            height: 3px;
                            background-color: $primary;
                            bottom: 0;
                        }
                    }
                }

                @include media-breakpoint-down(lg) {
                    background-color: #e5f1fa;
                    width: 200px;
                    padding: 10px 0;
                }

                @include media-breakpoint-down(sm) {
                    width: 100vw;
                }
            }
        }

        .logo-wrapper {
            @include media-breakpoint-down(lg) {
                order: 0;
            }

            .logo {
                img {
                    max-width: 114px;
                    width: 100%;

                    @include media-breakpoint-down(sm) {
                        width: 80px;
                    }
                }

                svg,
                img {
                    // position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto 0 auto 0;
                    height: 30px;

                    @media (min-width: 370px) {
                        height: 40px;
                    }

                    @include media-breakpoint-up(sm) {
                        height: 50px;
                    }

                    @include media-breakpoint-up(xxl) {
                        height: 75px;
                    }

                    /* Logo Animation */

                    #over {
                        opacity: 0;
                        animation-name: rotateIn;
                        animation-duration: 0.7s;
                        animation-delay: 0.3s;
                        z-index: 1;
                        animation-timing-function: ease-out;
                        animation-fill-mode: forwards;
                        transform-origin: 25% 50%;
                        transform: rotate(-90deg);
                    }

                    #under {
                        opacity: 0;
                        animation-name: rotateIn;
                        animation-duration: 0.9s;
                        animation-delay: 0.3s;
                        z-index: 1;
                        animation-timing-function: ease-out;
                        animation-fill-mode: forwards;
                        transform-origin: 25% 50%;
                        transform: rotate(-90deg);
                    }

                    #logo {
                        opacity: 0;
                        animation-name: fadeIn;
                        animation-duration: 0.9s;
                        animation-delay: 0.5s;
                        z-index: 1;
                        animation-timing-function: ease-out;
                        animation-fill-mode: forwards;
                        transform: translate(0, 0);
                    }

                    @keyframes rotateIn {
                        0% {
                            opacity: 0;
                        }

                        100% {
                            opacity: 1;
                            transform: rotate(0deg);
                        }
                    }

                    @keyframes fadeIn {
                        0% {
                            opacity: 0;
                        }

                        100% {
                            opacity: 1;
                            transform: translate(0, 0px);
                        }
                    }

                    .st0 {
                        fill: #1d1e1c;
                    }

                    .st1 {
                        fill: #0076be;
                    }

                    .st2 {
                        fill: #1d1d1b;
                    }

                    .st3 {
                        fill: url(#SVGID_1_);
                    }

                    .st4 {
                        fill: url(#SVGID_2_);
                    }
                }
            }
        }

        .navbar {
            a {
                height: 100%;
            }

            p {
                font-size: 16px;

                @include media-breakpoint-down(sm) {
                    font-size: 14px;
                }
            }
        }

        .navbar-toggler {
            color: black;
            border: 0;
            border-radius: 0;

            &:focus {
                box-shadow: none;
            }

            @include media-breakpoint-down(lg) {
                // order: 1;
                padding: 0 !important;
            }

            p {
                font-size: 16px;

                @include media-breakpoint-down(sm) {
                    font-size: 15px;
                }
            }

            i.fa-bars {
                padding: 5px 8px;
                color: $primary;
                font-size: 16px;

                @include media-breakpoint-down(xxss) {
                    font-size: 14px;
                }
            }
        }

        .btn-search {
            height: 100%;
            width: 86px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $secondary;
            border-radius: 0;

            @include media-breakpoint-down(lg) {
                order: 1;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                text-transform: none;
            }

            @include media-breakpoint-down(md) {
                padding: 0 0px;
                width: 50px;
            }

            @include media-breakpoint-down(xs) {
                padding-top: 7px !important;
            }

            .bi-search {
                font-size: 20px;

                @include media-breakpoint-down(lg) {
                    font-size: 24px;
                }
            }
        }

        .img-user {
            @include media-breakpoint-down(lg) {
                order: 4;
            }
        }

        .img-basket {
            @include media-breakpoint-down(lg) {
                order: 5;
            }
        }

        .nav-link {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: auto;
            color: black;

            span {
                white-space: nowrap;
                padding: 0 1px;

                @include media-breakpoint-up(xxl) {
                    padding: 0 10px;
                }
            }
        }

        .mobile-nav-item {
            display: none;
        }

        @media (max-width: 340px) {
            .logo-wrapper {
                order: 0;

                .logo-wrapper > a > img {
                    width: 100% !important;
                }
            }

            .navbar-toggler {
                order: 0;
            }

            .btn-search {
                order: 2;
            }

            .img-basket,
            .img-user {
                display: none !important;
            }

            .mobile-nav-item {
                display: block;
            }
        }

        .main-menu-list {
            @include media-breakpoint-between(md, xl) {
                li a {
                    font-size: 14px;
                }
            }

            @include media-breakpoint-up(xxxl) {
                width: 100%;

                li a {
                    font-size: 18px;
                }
            }

            @include media-breakpoint-up(xxxl) {
                li {
                    min-width: 125px;
                }
            }
        }
    }
}

.megamenu-container {
    // @include media-breakpoint-up(lg) {
    //     padding-top: 100px;
    //     margin-top: -100px;
    // }

    @include media-breakpoint-down(lg) {
        max-width: unset;
    }
}

header .megamenu {
    display: none;
}

.categories-page-top {
    padding: 18px 0;
    background-color: $gray-100;
    border-bottom: 1px solid $gray-ef;
    border-top: 1px solid $gray-ef;

    .container > div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            font-size: 16px;
        }

        .products-count {
            color: #828282;
        }
    }
}

.category-page-menu {
    background-color: white;

    width: 100%;

    .list-top {
        padding-bottom: 40px;
        margin-bottom: 30px;
        border-bottom: 1px solid $gray-ef;

        h2 {
            margin-bottom: 15px;
            font-size: 40px;
        }

        p {
            font-size: 16px;
            font-weight: normal;
        }

        button {
            font-size: 16px;
            padding: 10px;
            font-weight: normal;
        }
    }

    .list {
        h4 {
            margin-bottom: 10px;
        }

        ul {
            list-style: none;
            padding: 0;

            li.nav-item {
                &:hover {
                    color: #0077cf;
                    border: 1px solid #0077cf;
                    background: rgba(0, 119, 207, 0.07);
                    border-radius: 10px;
                    padding-right: 35px;
                    position: relative;

                    &::before {
                        content: url("data:image/svg+xml,%3Csvg width='16' height='13' viewBox='0 0 16 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.73141 0.709626L8.38477 2.18086L11.7069 5.81731L-3.13892e-07 5.81731L-2.22909e-07 7.89925L11.7069 7.89925L8.38477 11.5288L9.73141 13L15.3594 6.85828L9.73141 0.709626Z' fill='%230077CF'/%3E%3C/svg%3E%0A");
                        position: absolute;
                        right: 15px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }

                button.nav-link,
                a.nav-link {
                    text-align: left;
                    font-weight: bold;
                    width: 100%;
                    justify-content: flex-start;
                    padding: 15px;
                    color: black;

                    &.active {
                        outline: 1px solid #0077cf;
                        color: #0077cf;
                        background: rgba(0, 119, 207, 0.07);
                        border-radius: 10px;
                        padding-right: 35px;
                        position: relative;

                        &::before {
                            content: url("data:image/svg+xml,%3Csvg width='16' height='13' viewBox='0 0 16 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.73141 0.709626L8.38477 2.18086L11.7069 5.81731L-3.13892e-07 5.81731L-2.22909e-07 7.89925L11.7069 7.89925L8.38477 11.5288L9.73141 13L15.3594 6.85828L9.73141 0.709626Z' fill='%230077CF'/%3E%3C/svg%3E%0A");
                            position: absolute;
                            right: 15px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
        }
    }

    .content {
        .category-item {
            position: relative;
            margin-bottom: 10px;
            border: 1px solid $gray-ef;
            border-radius: 10px;
            padding: 30px 30px 3px 30px;

            &.sub {
                padding: 20px;

                > div {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                svg {
                    width: 100%;
                }

                .cat-item-title {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    margin-top: 10px;
                }

                &:hover {
                    background: rgba(0, 119, 207, 0.07);
                    border: 1px solid #0077cf;
                }
            }

            @include media-breakpoint-up(lg) {
                margin-bottom: 30px;
            }

            img {
                display: block;
                margin: 0 auto;
                width: 100%;
                max-width: 250px;
            }

            .cat-item-title {
                min-height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 20px;
            }

            &:not(.sub):hover {
                .cat-item-title {
                    color: #0077cf;
                    border: 1px solid #0077cf;
                    background: rgba(0, 119, 207, 0.07);
                    border-radius: 10px;
                    position: relative;
                }
            }
        }
    }

    .sub-sub-category {
        ul li {
            padding: 10px;
            padding-left: 24px;
            width: fit-content;
            cursor: pointer;

            img {
                display: none;
                position: absolute;
                right: 0;
                top: 0;
                max-width: 300px;
                max-height: 265px;
                object-fit: cover;
            }

            &:not(:last-child) {
                margin-bottom: 40px;
            }

            &:hover {
                img {
                    display: block;
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        flex-direction: column;

        .list {
            max-width: unset;
            height: auto;
            border-right: none !important;

            ul.nav-pills {
                flex-direction: row !important;
            }

            ul li {
                width: fit-content;
            }

            ul button.nav-link {
                &.active {
                    &::before {
                        transform: translateY(-35%) !important;
                        content: url("data:image/svg+xml,%3Csvg width='13' height='16' viewBox='0 0 13 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.8249 9.90659L11.3537 8.55994L7.71723 11.8821L7.71723 0.175171L5.63529 0.175171L5.63529 11.8821L2.00578 8.55994L0.534546 9.90659L6.67626 15.5345L12.8249 9.90659Z' fill='%230077CF'/%3E%3C/svg%3E%0A") !important;
                    }
                }
            }
        }

        .cat-header .title {
            flex: unset !important;
        }
    }

    @include media-breakpoint-down(xl) {
        .category-item.sub {
            .cat-item-title {
                font-size: 0.85rem;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .list {
            border-bottom: 1px solid $gray-ef;
        }
    }
}

@mixin truncate($font-size: $font-size-base,
    $line-height: $line-height-base,
    $lines-to-show: 2,
    $boundary: 100%,
    $static-height: true) {
    display: block;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    max-width: $boundary;
    text-overflow: ellipsis;
    font-size: $font-size;
    line-height: $line-height;
    -webkit-line-clamp: $lines-to-show;
    white-space: normal;

    @if $static-height==true {
        height: $font-size * $line-height * $lines-to-show !important;
    }

    @else {
        max-height: $font-size * $line-height * $lines-to-show;
        word-break: break-all;
    }
}

@mixin center($horizontal: true, $vertical: true) {
    position: absolute;

    @if ($horizontal and $vertical) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @else if ($horizontal) {
        left: 50%;
        transform: translate(-50%, 0);
    }

    @else if ($vertical) {
        top: 50%;
        transform: translate(0, -50%);
    }
}

@mixin truncate-single($truncate-boundary: 100%) {
    display: block;
    overflow: hidden;
    max-width: $truncate-boundary;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@each $breakpoint,
$container-max-width in $container-max-widths {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        .container-half {
            max-width: calc(#{$container-max-width}/2);
        }

        .margin-left-half {
            margin-left: calc(50% - #{$container-max-width}/2);
        }
    }
}